export const tiers = {
    // clover
    Free: { amount: 10, price: "Free", period: "" },
    "Tier 1": { amount: -1, price: "$12.99", period: "Month" },
    "Tier 2": { amount: -1, period: "Month", price: "$29.99" },
    "Tier 3": { amount: -1, period: "Month", price: "$49.99" },
    Unlimited: { amount: -1, period: "Month", price: "$99.99" },
    // new clover tiers
    Starter: { amount: 250, period: "Month", price: "$9.99" },
    Enhanced: { amount:  -1, period: "Month", price: "$19.99" },
    Pro: { amount:  -1, period: "Month", price: "$29.99" },
    Premier: { amount:  -1, period: "Month", price: "$49.99" },
  
    // stripe
    "1000 Cards Monthly": { amount: -1, period: "Month", price: "$12.99" },
    "5000 Cards Monthly": { amount: -1, period: "Month", price: "$29.99" },
    "15000 Cards Monthly": { amount: -1, period: "Month", price: "$49.99" },
  
    "Unlimited Cards Monthly": { amount: -1, period: "Year", price: "$99.99" },
    "1000 Cards Yearly": { amount: -1, period: "Year", price: "$129.99" },
    "5000 Cards Yearly": { amount: -1, period: "Year", price: "$299.99" },
    "15000 Cards Yearly": { amount: -1, period: "Year", price: "$499.99" },
    "Unlimited Cards Yearly": { amount: -1, period: "Year", price: "$999.99" },
    // new stripe tiers
    "Starter Monthly": { amount: 250, period: "Month", price: "$9.99" },
    "Starter Yearly": { amount: 250, period: "Year", price: "$99.99" },
    "Enhanced Monthly": { amount: -1, period: "Month", price: "$19.99" },
    "Enhanced Yearly": { amount: -1, period: "Year", price: "$199.99" },
    "Pro Monthly": { amount: -1, period: "Month", price: "$29.99" },
    "Pro Yearly": { amount: -1, period: "Year", price: "$299.99" },
    "Premier Monthly": { amount: -1, period: "Month", price: "$49.99" },
    "Premier Yearly": { amount: -1, period: "Year", price: "$499.99" },

    // new unlimited tiers
    "Unlimited Enhanced": { amount: -1, period: "Month", price: "$19.99" },
    "Unlimited Pro": { amount: -1, period: "Month", price: "$29.99" },
    "Unlimited Premier": { amount: -1, period: "Month", price: "$49.99" },
    "Unlimited Enterprise": { amount: -1, period: "Month", price: "$99.99" },

    // custom stripe tier for customer
    "Unlimited Enterprise Mango": { amount: -1, period: "Month", price: "$96.00" },
  };