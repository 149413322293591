import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import PrivateRouteLoggedIn from './Utils/PrivateRouteLoggedIn';
import PrivateRouteLoggedOut from './Utils/PrivateRouteLoggedOut';
import Pages from './Pages';
import { AuthProvider } from './Contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/main.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route exact path="/"><Redirect to="/Login"/></Route>
          <PrivateRouteLoggedOut exact path='/login' component={Pages.Login} />
          <PrivateRouteLoggedOut exact path='/logout' component={Pages.Logout} />
          <PrivateRouteLoggedOut exact path='/password-reset' component={Pages.ForgotPassword} />
          <PrivateRouteLoggedIn exact path="/dashboard" component={Pages.Dashboard} />
          <PrivateRouteLoggedIn exact path="/help" component={Pages.Help} />
          <PrivateRouteLoggedIn exact path='/bulk-upload' component={Pages.ImportCards} />
          <PrivateRouteLoggedIn exact path='/number-generator' component={Pages.Generator} />
          <PrivateRouteLoggedIn exact path='/card-printer' component={Pages.CardPrinter} />
          <PrivateRouteLoggedIn exact path='/reports' component={Pages.Reports} />
          <PrivateRouteLoggedIn exact path='/reports/number-generator-history' component={Pages.GeneratorReport} />
          <PrivateRouteLoggedIn exact path='/reports/bulk-upload-history' component={Pages.UploadReport} />
          <PrivateRouteLoggedIn exact path='/reports/all-active-cards' component={Pages.ActiveCardsReport} />
          <PrivateRouteLoggedIn exact path='/reports/all-inactive-cards' component={Pages.InactiveCardsReport} />
          <PrivateRouteLoggedIn exact path='/reports/used-cards' component={Pages.UsedCardsReport} />
          <PrivateRouteLoggedIn exact path='/reports/usage-log' component={Pages.UsageLog} />
          <PrivateRouteLoggedIn exact path='/reports/location-redemption' component={Pages.LocationRedemption} />
          <PrivateRouteLoggedIn exact path='/profile' component={Pages.Profile} />
          <PrivateRouteLoggedIn exact path='/subscribe' component={Pages.Subscribe} />
          <PrivateRouteLoggedIn exact path='/change-plan' component={Pages.ChangePlan} />
          <Route exact path="/page-not-found" component={Pages.PageNotFound} />
          <Redirect to="/page-not-found" />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;