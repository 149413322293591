import { useState, useEffect, useRef } from 'react';

const useMenuVisible = arg => {
    const [showMenu, setShowMenu] = useState(arg);
    const ref = useRef(null);
  
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { ref, showMenu, setShowMenu };
  }

export default useMenuVisible;