import React from "react";
import { useRecoilState } from "recoil";
import { MenuState } from "../../Atoms/NavAtoms";

const Burger = () => {
  const [menuState, setMenuState] = useRecoilState(MenuState);

  return (
    <div className="burger" onClick={() => setMenuState(!menuState)}>
      <div className={menuState ? "bar1" : undefined} />
      <div className={menuState ? "bar2" : undefined} />
      <div className={menuState ? "bar3" : undefined} />
    </div>
  );
};

export default Burger;
