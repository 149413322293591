const PlusIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.375 10.0781H16.125V11.4219H5.375V10.0781Z" fill="#333333" />
      <path
        d="M11.4219 5.375L11.4219 16.125H10.0781L10.0781 5.375H11.4219Z"
        fill="#333333"
      />
    </svg>
  );
};

export default PlusIcon;
