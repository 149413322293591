import { useState } from "react";
import { Spinner, Pricing } from "../../Components";
import axios from "axios";
import { useAuth } from "../../Contexts/AuthContext";
import { useHistory } from "react-router-dom";
import AppContainer from "../../Components/AppContainer/AppContainer";

const Payment = () => {
  const userId = useAuth().currentUser.uid;
  const history = useHistory();

  const [showPayment, setShowPayment] = useState(false);
  const [prorateAmount, setProrateAmount] = useState("");
  const [price, setPrice] = useState("");
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tierName, setTierName] = useState("");

  const getInvoice = async (tier) => {
    setPrice(tier);
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/stripe/upcomingInvoice/${userId}`,
      { price_id: tier }
    );
    setProrateAmount(res.data.total);
    setShowPayment(true);
  };

  const handleSubmitUpdate = async () => {
    setShowPayment(false);
    setShowConfirmUpdate(true);
    await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/stripe/updateSub/${userId}`,
      { price_id: price }
    );
    setLoading(false);
    handleRedirect();
  };

  const handleRedirect = () => {
    setTimeout(async () => {
      history.push("/profile");
    }, 2000);
  };

  const ConfirmUpdate = () => {
    return (
      <>
        <div className="test-background" />
        {loading ? (
          <div className="test-modal d-flex flex-column align-items-center justify-content-center">
            <h3>Processing...</h3>
            <Spinner />
          </div>
        ) : (
          <div className="test-modal d-flex flex-column align-items-center justify-content-center">
            <h3>Update Successful</h3>
            <h5> Redirecting...</h5>
          </div>
        )}
      </>
    );
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Pricing
          getTier={(tier) => getInvoice(tier)}
          getTierName={(tierName) => setTierName(tierName)}
        />
        {showPayment && (
          <>
            <div
              onClick={() => setShowPayment(false)}
              className="test-background"
            />
            <div className="test-modal">
              <section className="pricing py-5">
                <div className="container">
                  <div className="row">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h4>{tierName}</h4>
                      <h4>
                        Upcoming Billing Amount (Prorated):{" "}
                        {prorateAmount / 100}
                      </h4>
                      <div>
                        <button
                          onClick={handleSubmitUpdate}
                          className="profile-btn-modal rounded m-2"
                          type="submit"
                        >
                          Update
                        </button>
                        <button
                          onClick={() => setShowPayment(false)}
                          className="profile-btn-modal rounded m-2"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
        {showConfirmUpdate && ConfirmUpdate()}
      </div>
    </AppContainer>
  );
};

export default Payment;
