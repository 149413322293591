const VerticalLinearGradientBar = ({ height, width }) => {
  return (
    <div
      style={{
        height: `${height}`,
        width: `${width}`,
        background: "linear-gradient(0deg, #A3CD65 17.84%, #6894BA 90.47%)",
      }}
    ></div>
  );
};

export default VerticalLinearGradientBar;
