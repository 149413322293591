import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import { Button } from "react-bootstrap";
import { tiers } from "../../Data/Tiers";
import axios from "axios";
import TextField from "@mui/material/TextField";
import AppContainer from "../../Components/AppContainer/AppContainer";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Dashboard = () => {
  const [merchantName, setMerchantName] = useState("");
  // this month
  const [thisMonthSales, setThisMonthSales] = useState("Loading...");
  const [thisMonthCards, setThisMonthCards] = useState("Loading...");
  // last month
  const [lastMonthSales, setLastMonthSales] = useState("Loading...");
  const [lastMonthCards, setLastMonthCards] = useState("Loading...");
  // total
  const [totalSales, setTotalSales] = useState("Loading...");
  const [totalCards, setTotalCards] = useState("Loading...");
  // subscription
  const [hasStripeSubscription, setHasStripeSubscription] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState("Loading...");
  const [subscriptionCardLimit, setSubscriptionCardLimit] = useState(0);
  const [remainingCards, setRemainingCards] = useState(0);

  const [subscriptionPrice, setSubscriptionPrice] = useState("Loading...");
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("");

  const id = useAuth().currentUser.uid;

  useEffect(() => {
    (async () => {
      const [merchantName, dMtd, dTotal, cMtd,cTotal, dLastMonth, cLastMonth, cloverSubscription, stripeSubscription, activeCardsCount] = await Promise.allSettled([
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/merchant-name/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/dollars-mtd/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/dollars-total/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/quantity-mtd/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/quantity-total/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/dollars-last-month/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/quantity-last-month/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/billing/getSubscription/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/stripe/accountInfo/${id}`),
        axios.get(`${process.env.REACT_APP_BACKEND_API}/reports/active-cards-count/${id}`),
      ]);

      console.log("merchant name", merchantName.value?.data);
      console.log("clover sub", cloverSubscription.value?.data);
      console.log("stripe sub", stripeSubscription.value?.data?.subTier);
      console.log("active cards:", activeCardsCount.value?.data?.amount);
      console.log("stripe subscription : ", stripeSubscription.value?.data?.subTier);
      console.log("clover subscription : ", cloverSubscription.value?.data);

      if (tiers[stripeSubscription.value?.data?.subTier]?.price === undefined) {
        // on clover subscription
        setHasStripeSubscription(false);
        if (tiers[cloverSubscription.value?.data]?.price === undefined) {
          // no subscription found
          setSubscriptionName("n/a");
          setSubscriptionPrice("n/a");
        } else {
          setSubscriptionCardLimit(tiers[cloverSubscription.value?.data]?.amount);
          setRemainingCards(tiers[cloverSubscription.value?.data]?.amount - activeCardsCount.value?.data?.amount);
          setSubscriptionName(cloverSubscription.value?.data);
          setSubscriptionPrice(tiers[cloverSubscription.value?.data]?.price);
          setSubscriptionPeriod(tiers[cloverSubscription.value?.data]?.period);
        }
      } else {
        // on stripe subscription
        setHasStripeSubscription(true);
        setSubscriptionCardLimit(tiers[stripeSubscription.value?.data?.subTier]?.amount);
        setRemainingCards(
          tiers[stripeSubscription.value?.data?.subTier]?.amount - activeCardsCount.value?.data?.amount
        );
        setSubscriptionName(stripeSubscription.value?.data?.subTier);
        setSubscriptionPrice(tiers[stripeSubscription.value?.data?.subTier]?.price);
        setSubscriptionPeriod(tiers[stripeSubscription.value?.data?.subTier]?.period);
      }

      setMerchantName(merchantName.value?.data);
      setThisMonthSales(dMtd.value?.data !== undefined ? `$${numberWithCommas(dMtd.value?.data)}` : "");
      setThisMonthCards(cMtd.value?.data !== undefined ? numberWithCommas(cMtd.value?.data) : "");
      setLastMonthSales(dLastMonth.value?.data !== undefined ? `$${numberWithCommas(dLastMonth.value?.data)}` : "");
      setLastMonthCards(cLastMonth.value?.data !== undefined ? numberWithCommas(cLastMonth.value?.data) : "");
      setTotalSales(dTotal.value?.data !== undefined ? `$${numberWithCommas(dTotal.value?.data)}` : "");
      setTotalCards(cTotal.value?.data !== undefined ? numberWithCommas(cTotal.value?.data) : "");
    })();
  }, [id]);

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "1150px",
          margin: "0 auto",
        }}
      >
        <div className="welcome-text">
          <h2>Welcome, {merchantName}</h2>
        </div>
        <SubscriptionPlanCard
          planName={subscriptionName}
          totalCards={subscriptionCardLimit}
          remainingCards={remainingCards}
          subscriptionPrice={subscriptionPrice}
          period={subscriptionPeriod}
          hasStripeSubscription={hasStripeSubscription}
        />
        <div className="month-cards-container">
          <MonthlySalesCard title="This Month" totalSales={thisMonthSales} cardsSold={thisMonthCards} />
          <MonthlySalesCard title="Last Month" totalSales={lastMonthSales} cardsSold={lastMonthCards} />
        </div>
        <div className="small-cards-container">
          <CheckCardBalance />
          <CreateGiftCards />
          <LiabilityReportCard totalSales={totalSales} totalSold={totalCards} />
        </div>
      </div>
    </AppContainer>
  );
};

export default Dashboard;

const SubscriptionPlanCard = ({
  planName,
  totalCards,
  remainingCards,
  subscriptionPrice,
  period,
  hasStripeSubscription,
}) => {
  const history = useHistory();

  return (
    <div className="subscription-plan-card">
      <div className="my-plan">
        <strong style={{ fontSize: "16px" }}>My Plan</strong>
        <div style={{ fontSize: "18px" }}>{planName}</div>
      </div>
      <div className="sub-divider">
        <VerticalDivider height={46} />
      </div>
      <div className="sub-cards-info">
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: "16px" }}>Total Cards</div>
          <div style={{ fontSize: "22px" }}>{totalCards === -1 ? "unlimited" : totalCards}</div>
        </div>
        <VerticalDivider height={46} />
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: "16px" }}>Remaining</div>
          <div style={{ fontSize: "22px" }}>{totalCards === -1 ? "unlimited" : remainingCards}</div>
        </div>
      </div>
      <div className="sub-divider">
        <VerticalDivider height={46} />
      </div>
      <div className="sub-price">
        <div style={{ fontSize: "16px" }}>Subscription</div>
        <div style={{ fontSize: "22px" }}>
          {subscriptionPrice}
          <span style={{ fontSize: "14px" }}>{period === "" ? "" : `/${period}`}</span>
        </div>
      </div>
      <div className="sub-plan-btn">
        <Button
          onClick={() => history.push(`${hasStripeSubscription ? "/change-plan" : "/subscribe"}`)}
          style={{
            height: "36px",
            width: "216px",
            fontSize: "14px",
            margin: "0 0 0 0",
          }}
          className="shadow-none get-balance-button"
        >
          UPGRADE SUBSCRIPTION
        </Button>
      </div>
    </div>
  );
};

const MonthlySalesCard = ({ title, totalSales, cardsSold }) => {
  return (
    <div className="monthly-sales-card">
      <div style={{ fontSize: "20px", fontWeight: "700", marginBottom: "40px" }}>{title}</div>
      <div className="monthly-sales-data">
        <div style={{ width: "50%" }}>
          <div style={{ fontSize: "18px" }}>Total Sales</div>
          <div style={{ fontSize: "50px", marginTop: "-10px" }}>{totalSales}</div>
        </div>
        <div className="monthly-sales-divider">
          <VerticalDivider height={82} />
        </div>
        <div className="monthly-sales-cards-sold">
          <div style={{ fontSize: "18px" }}>Cards Sold</div>
          <div style={{ fontSize: "50px", marginTop: "-10px" }}>{cardsSold}</div>
        </div>
      </div>
    </div>
  );
};

const CheckCardBalance = () => {
  const [balance, setBalance] = useState("");
  const [input, setInput] = useState("");
  const [errorMsg, setErrorMsg] = useState(" ");

  const { currentUser } = useAuth();
  const userId = currentUser.uid;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/balance/${userId}/${input}`);

      if (response.data.balance === undefined) {
        setBalance("");
        if (response.data.status === "404") {
          setErrorMsg("Card not found");
        }
        if (response.data.status === "400") {
          setErrorMsg("Card not activated");
        }
      } else {
        setBalance(`$${response.data.balance}`);
        setErrorMsg(" ");
      }
    } catch (error) {
      setBalance("");
      setErrorMsg("Invalid Number");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="check-card-balance">
      <div style={{ fontSize: "20px", fontWeight: "700" }}>Check Gift Card Balance</div>
      <TextField
        onChange={(e) => setInput(e.target.value)}
        sx={{ backgroundColor: "white", marginTop: "14px" }}
        size="small"
        id="outlined-basic"
        label="Enter Card Number"
        variant="outlined"
        error={errorMsg === " " ? false : true}
        FormHelperTextProps={{
          style: { backgroundColor: "#edecea", margin: 0, paddingLeft: 12 },
        }}
        helperText={errorMsg}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontSize: "16px", fontWeight: "500", marginLeft: "8px" }}>{balance}</div>
        <Button
          type="submit"
          style={{
            height: "30px",
            width: "160px",
            fontSize: "14px",
            margin: "0 0 0 0",
          }}
          className="shadow-none get-balance-button"
        >
          CHECK BALANCE
        </Button>
      </div>
    </form>
  );
};

const CreateGiftCards = () => {
  const history = useHistory();

  return (
    <div className="create-gift-cards">
      <div style={{ fontSize: "20px", fontWeight: "700" }}>Create Gift Cards</div>
      <div style={{ fontSize: "14px", lineHeight: "16px" }}>
        Create custom gift cards using our card number generator and card printer editor.
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => history.push("/number-generator")}
          style={{
            height: "30px",
            width: "160px",
            fontSize: "14px",
            margin: "0 0 0 0",
          }}
          className="shadow-none get-balance-button"
        >
          CREATE CARDS
        </Button>
      </div>
    </div>
  );
};

const LiabilityReportCard = ({ totalSales, totalSold }) => {
  const history = useHistory();

  return (
    <div className="liability-report">
      <div style={{ fontSize: "20px", fontWeight: "700" }}>Liability Report</div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ fontSize: "12px" }}>Total Sales</div>
          <div style={{ fontSize: "25px" }}>{totalSales}</div>
        </div>
        <VerticalDivider height={35} />
        <div style={{ textAlign: "center", marginRight: "20px" }}>
          <div style={{ fontSize: "12px" }}>Total Sold</div>
          <div style={{ fontSize: "25px" }}>{totalSold}</div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => history.push("/reports/all-active-cards")}
          style={{
            height: "30px",
            width: "160px",
            fontSize: "14px",
            margin: "0 0 0 0",
          }}
          className="shadow-none get-balance-button"
        >
          PRINT
        </Button>
      </div>
    </div>
  );
};

const VerticalDivider = ({ height }) => {
  return (
    <div
      style={{
        backgroundColor: "#A3CD6580",
        width: "2px",
        height: `${height}px`,
      }}
    ></div>
  );
};
