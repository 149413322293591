import React from 'react'

const PageNotFound = () => {
    return (
        <div>
            <h1>Oh No! Page Not Found</h1>
        </div>
    )
}

export default PageNotFound
