import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { useAuth } from "../../Contexts/AuthContext";
import { Loading } from "../../Components";
import CurrentDate from "../../Utils/CurrentDate";
import Icons from "../../Images/Icons";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import AppContainer from "../../Components/AppContainer/AppContainer";
import { Button } from "react-bootstrap";

const utcToDateAndTime = (utcString) => {
  const utcTimestamp = parseInt(utcString);
  const date = new Date(utcTimestamp);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
};

const InactiveCardsReport = () => {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const { currentUser } = useAuth();
  const { width } = useWindowDimensions();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/inactive-cards/${currentUser.uid}`
        );

        let temp = [];
        if (response.data.cards !== undefined) {
          response.data.cards.forEach((card) => {
            if (card.cardNumber?.includes("ARCHIVE")) {
              const currentCard = card.cardNumber;
              const archiveIndex = currentCard.indexOf("ARCHIVE");
              const prefix = currentCard.substring(0, archiveIndex); // card number "ABC123"
              const suffix = currentCard.substring(archiveIndex + 7); // utc "1678918932592"

              card.cardNumber = prefix;
              card.generatedDate = `Archived ${utcToDateAndTime(suffix)}`;
            }
            temp.push({
              count: response.data.cards.indexOf(card) + 1,
              cardNumber: card.cardNumber,
              generatedDate: card.generatedDate,
              uploadedDate: card.uploadedDate,
              state: card.state,
            });
          });
        }

        setCards(temp);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    })();
  }, [currentUser.uid]);

  const header = [
    { label: "Count", key: "count" },
    { label: "Card Number", key: "cardNumber" },
    { label: "Generated Date", key: "generatedDate" },
    { label: "Uploaded Date", key: "uploadedDate" },
    { label: "Card Status", key: "state" },
  ];

  const CSVReport = {
    data: cards,
    headers: header,
    filename: `inactive-cards-${CurrentDate()}.csv`,
  };

  const ExportButton = ({ small = false }) => {
    if (cards !== undefined && cards.length !== 0) {
      return (
        <div
          style={{
            display: small ? "" : "flex",
            margin: small ? "" : "40px 0 20px 0",
            justifyContent: small ? "" : "flex-end",
          }}
        >
          <Button
            style={{
              height: small ? "30px" : "44px",
              width: small ? "100%" : "",
              marginBottom: small ? "20px" : "",
              padding: 0,
            }}
            className="shadow-none reports-button"
          >
            <CSVLink
              style={{
                width: small ? "" : "200px",
                height: "44px",
                textDecoration: "none",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
              }}
              {...CSVReport}
            >
              <span style={{ marginRight: "10px" }}>EXPORT</span>
              <Icons.Download />
            </CSVLink>
          </Button>
        </div>
      );
    }
  };

  const GetCards = () => {
    if (cards !== undefined && cards.length !== 0) {
      return width < 900 ? (
        cards.map((card, index) => {
          return (
            <div key={index} className="dateCard">
              <div className="active-card-number">
                Card Number: {card.cardNumber}
              </div>
              {card.uploadedDate ? (
                <div>Uploaded Date: {card.uploadedDate}</div>
              ) : (
                <div>Generated / Archived Date: {card.generatedDate}</div>
              )}
              <div key={index} className="active-card-status">
                Status: {`${card.state[0].toUpperCase()}${card.state.slice(1)}`}
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            border: "1px solid lightgray",
            width: "100%",
            height: "356px",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table className="table-generator-history">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr style={{ backgroundColor: "#d2dee7" }}>
                <th>Card Number</th>
                <th>Status</th>
                <th>Generated / Archived Date</th>
              </tr>
            </thead>
            <tbody>
              {cards.map((card, index) => {
                return (
                  <tr key={index}>
                    <td>{card.cardNumber.toUpperCase()}</td>
                    <td>Inactive</td>
                    <td>{card.generatedDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return <div className="no-cards-message">No inactive cards found</div>;
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "80%", maxWidth: "1100px" }}>
          <h2 className="inactive-cards-header">Inactive Cards</h2>
          {width < 900 && !loading ? <ExportButton small={true} /> : null}
          {cards.length !== 0 && (
            <div className="inactive-cards-total">{`${cards.length} cards`}</div>
          )}
          {loading ? <Loading /> : null}
          {!loading ? <GetCards /> : null}
          {width >= 900 && !loading ? <ExportButton /> : null}
        </div>
      </div>
    </AppContainer>
  );
};

export default InactiveCardsReport;
