import Dashboard from '../Pages/Dashboard/Dashboard';
import Help from '../Pages/Help/Help';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import Generator from '../Pages/Generator/Generator';
import CardPrinter from '../Pages/CardPrinter/CardPrinter';
import ImportCards from '../Pages/ImportCards/ImportCards';
import Reports from '../Pages/Reports/Reports';
import GeneratorReport from '../Pages/GeneratorReport/GeneratorReport';
import UploadReport from '../Pages/UploadReport/UploadReport';
import ActiveCardsReport from '../Pages/ActiveCardsReport/ActiveCardsReport';
import InactiveCardsReport from '../Pages/InactiveCardsReport/InactiveCardsReport';
import UsedCardsReport from '../Pages/UsedCards/UsedCards';
import UsageLog from '../Pages/UsageLog/UsageLog'
import Login from '../Pages/Login/Login';
import Logout from '../Pages/Logout/Logout';
import PageNotFound from '../Pages/PageNotFound/PageNotFound';
import Profile from '../Pages/Profile/Profile';
import Subscribe from '../Pages/Subscribe/Subscribe';
import ChangePlan from '../Pages/ChangePlan/ChangePlan';
import LocationRedemption from '../Pages/LocationRedemption/LocationRedemption'

const Pages = {
    Dashboard,
    Help,
    ForgotPassword,
    Generator,
    CardPrinter,
    ImportCards,
    Login,
    Logout,
    PageNotFound,
    Profile,
    Reports,
    UsageLog,
    GeneratorReport,
    UploadReport,
    ActiveCardsReport,
    InactiveCardsReport,
    UsedCardsReport,
    Subscribe,
    ChangePlan,
    LocationRedemption
}

export default Pages;