import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import { getStorage } from "firebase/storage";

const app = firebase.initializeApp({
    apiKey: "AIzaSyDnwTxCL9QmiMuiGKoI9OYTD4vRI9Z1pFw",
    authDomain: "cocoa-giftcards.firebaseapp.com",
    projectId: "cocoa-giftcards",
    storageBucket: "cocoa-giftcards.appspot.com",
    messagingSenderId: "148572589433",
    appId: "1:148572589433:web:5b5246fa5ff7a8a14df3a8"
})

export const storage = getStorage(app);
export const auth = app.auth();
export default auth