import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import Icons from "../../Images/Icons";
import { useAuth } from "../../Contexts/AuthContext";
import { Loading } from "../../Components";
import { Button } from "react-bootstrap";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import { useHistory } from "react-router-dom";
import AppContainer from "../../Components/AppContainer/AppContainer";

const UploadReport = () => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [dates, setDates] = useState();
  const [cards, setCards] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/upload-dates/${currentUser.uid}`
        );
        setDates(response.data.dates);
      } catch (err) {
        console.log(`Something went wrong: ${err}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentUser.uid]);

  const header = [
    { label: "Count", key: "count" },
    { label: "Number", key: "cardNumber" },
    { label: "Amount", key: "cardAmount" },
    { label: "ActivatedDate", key: "dateSold" },
    { label: "CardType", key: "cardType" },
    { label: "Category", key: "category" },
    { label: "Uploaded Date", key: "uploadedDate" },
    { label: "Card Status", key: "state" },
  ];

  const CSVReport = {
    data: cards,
    headers: header,
    filename: `cards-uploaded-on-${selectedDate.replaceAll(" ", "-")}.csv`,
  };

  const getCardsByDate = async (e, date) => {
    resetCards();
    setLoading(true);
    e.preventDefault();
    setSelectedDate(date);
    const thisDate = date;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/reports/upload-cards/${currentUser.uid}/${thisDate}`
      );

      console.log(`response.data.cards`, response.data.cards);

      let temp = [];
      response.data.cards
        .sort((a, b) =>
          a.state === b.state ? 0 : a.state === "inactive" ? 1 : -1
        ) // sort cards so active ones are on top
        .forEach((card) =>
          temp.push({
            count: response.data.cards.indexOf(card) + 1,
            cardNumber: card.cardNumber,
            cardAmount: card.cardAmount,
            dateSold: card.dateSold,
            cardType: card.cardType,
            category: card.category,
            generatedDate: card.generatedDate,
            uploadedDate: card.uploadedDate,
            state: card.state,
          })
        );

      setCards(temp);
    } catch (err) {
      console.log(`Getting cards with date failed: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  // reset the cards array to be empty
  const resetCards = () => setCards([]);

  const getDates = () => {
    if (dates !== undefined && dates.length !== 0) {
      return (
        <>
          <select
            className="dateSelect"
            onChange={(e) => getCardsByDate(e, e.target.value)}
            value={selectedDate}
          >
            <option value="" disabled>
              Filter by Date
            </option>
            {dates !== null &&
              dates.map((date, index) => {
                return (
                  <option
                    href=""
                    onClick={(e) => getCardsByDate(e, date)}
                    key={index}
                  >
                    {date}
                  </option>
                );
              })}
          </select>
        </>
      );
    }
    history.push("/bulk-upload");
  };

  // returns all cards imported on selected date to display card details
  const getCards = () => {
    if (cards !== undefined && cards.length > 0) {
      return width < 900 ? (
        cards.map((card, index) => {
          return (
            <div key={index} className="dateCard">
              <div>
                <strong>{`Card Number: ${card.cardNumber}`}</strong>
              </div>
              {card.cardAmount && <div>{`Balance: $${card.cardAmount}`}</div>}
              {card.cardType && <div>{`Card Type: ${card.cardType}`}</div>}
              {card.category && <div>{`Category: ${card.category}`}</div>}
              {card.dateSold && <div>{`Sold Date: ${card.dateSold}`}</div>}
              <div>{`Uploaded Date: ${card.uploadedDate}`}</div>
              {card.state === "active" ? (
                <div
                  style={{ color: "green" }}
                >{`State: ${card.state[0].toUpperCase()}${card.state.slice(
                  1
                )}`}</div>
              ) : (
                <div>{`State: ${card.state[0].toUpperCase()}${card.state.slice(
                  1
                )}`}</div>
              )}
            </div>
          );
        })
      ) : (
        <div
          style={{
            border: "1px solid lightgray",
            height: "356px",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table style={{ fontSize: "16px", width: "100%" }}>
            <thead style={{ position: "sticky", top: "0" }}>
              <tr style={{ backgroundColor: "#d2dee7" }}>
                <th>Card Number</th>
                <th>Status</th>
                <th>Type</th>
                <th>Category</th>
                <th>Balance</th>
                <th>Sold Date</th>
                <th>Uploaded Date</th>
              </tr>
            </thead>
            <tbody>
              {cards.map((card, index) => {
                return (
                  <tr key={index}>
                    <td>{card.cardNumber.toUpperCase()}</td>
                    <td>{`${card.state[0].toUpperCase()}${card.state.slice(
                      1
                    )}`}</td>
                    <td>{card.cardType}</td>
                    <td>{card.category}</td>
                    <td>{card.cardAmount}</td>
                    <td>{card.dateSold}</td>
                    <td>{card.uploadedDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const ButtonsContainer = ({ small = false }) => {
    if (selectedDate === "" || cards === undefined || cards.length === 0) {
      return <></>;
    }

    return (
      <div
        style={{
          display: small ? "" : "flex",
          margin: small ? "" : "40px 0 20px 0",
          justifyContent: small ? "" : "flex-end",
        }}
      >
        <button
          style={{
            width: small ? "100%" : "",
            height: small ? "30px" : "",
            fontSize: "16px",
            marginBottom: small ? "20px" : "",
            marginRight: small ? "" : "40px",
          }}
          className="import-more-btn"
          onClick={() => history.push("/bulk-upload")}
        >
          IMPORT MORE
        </button>
        <Button
          style={{
            height: small ? "30px" : "44px",
            width: small ? "100%" : "",
            marginBottom: small ? "20px" : "",
            padding: 0,
          }}
          className="shadow-none reports-button"
        >
          <CSVLink
            style={{
              width: small ? "" : "200px",
              height: "44px",
              textDecoration: "none",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
            }}
            {...CSVReport}
          >
            <span style={{ marginRight: "10px" }}>EXPORT</span>
            <Icons.Download />
          </CSVLink>
        </Button>
      </div>
    );
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "80%", maxWidth: "1100px" }}>
          <h2 className="imported-cards-header">Imported Cards</h2>
          <div
            className="imported-cards-header"
            style={{ margin: "30px 0 45px 0" }}
          >
            All cards that have been imported to Cocoa Gift Cards
          </div>
          {width < 900 ? <ButtonsContainer small={true} /> : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "14px",
            }}
          >
            {cards.length !== 0 && (
              <div className="imported-total-cards">{`${cards.length} cards`}</div>
            )}
            {!loading && <div>{getDates()}</div>}
          </div>
          {!loading ? <div>{getCards()}</div> : null}
          {!loading && width >= 900 ? <ButtonsContainer /> : null}
          {loading ? <Loading /> : null}
        </div>
      </div>
    </AppContainer>
  );
};

export default UploadReport;
