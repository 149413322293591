import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Pricing, PaymentForm } from "../../Components";
import AppContainer from "../../Components/AppContainer/AppContainer";

/**
 * Make sure to call `loadStripe` outside of a component’s render to avoid
 * recreating the `Stripe` object on every render.
 */
const stripePromise = loadStripe(
  "pk_live_51JxEqcFpMcYDokiAjhTh9d694i4YAYYuiq4vVdrpI3megP9GuXnmZrqpkRTbtD69U0sSZDPL5S6FYg0OeBe4nZTj000D1YHn6h"
);

const Payment = () => {
  const [showPayment, setPayment] = useState(false);
  const [price, setPrice] = useState("");
  const [tierName, setTierName] = useState("");

  function setTier(tier) {
    setPrice(tier);
    setPayment(true);
  }

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Pricing
          getTier={(tier) => setTier(tier)}
          getTierName={(tierName) => setTierName(tierName)}
        />
        <Elements stripe={stripePromise}>
          {showPayment && (
            <>
              <div
                onClick={() => setPayment(false)}
                className="test-background"
              />
              <div
                style={{
                  margin: 0,
                  position: "fixed",
                  zIndex: 12,
                  top: "35%",
                }}
              >
                <PaymentForm
                  price={price}
                  action="subscribe"
                  tierName={tierName}
                />
              </div>
            </>
          )}
        </Elements>
      </div>
    </AppContainer>
  );
};

export default Payment;
