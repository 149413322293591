import React, { useState, useEffect } from "react";
import { VerticalLinearGradientBar } from "../../Components";
import { useAuth } from "../../Contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import AppContainer from "../../Components/AppContainer/AppContainer";
import { tiers } from "../../Data/Tiers";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [hasStripeSubscription, setHasStripeSubscription] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [accountProfileModal, setAccountProfileModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  // account info - from db
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // subscription info
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionPrice, setSubscriptionPrice] = useState("");
  const [subscriptionCardLimit, setSubscriptionCardLimit] = useState("");
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("");

  const { resetPassword } = useAuth();
  const id = useAuth().currentUser.uid;
  const currentUser = useAuth();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const [
          cloverSubscription,
          merchantName,
          stripeSubscription,
          accountInfoFromDb,
        ] = await Promise.allSettled([
          axios(
            `${process.env.REACT_APP_BACKEND_API}/billing/getSubscription/${id}`
          ),
          axios(
            `${process.env.REACT_APP_BACKEND_API}/dashboard/merchant-name/${id}`
          ),
          axios(
            `${process.env.REACT_APP_BACKEND_API}/stripe/accountInfo/${id}`
          ),
          axios(
            `${process.env.REACT_APP_BACKEND_API}/account/account-info/${id}`
          ),
        ]);

        console.log(cloverSubscription.value?.data);
        console.log(merchantName.value?.data);
        console.log(stripeSubscription.value?.data);

        if (
          tiers[stripeSubscription.value?.data?.subTier]?.price === undefined
        ) {
          // on clover subscription
          setHasStripeSubscription(false);
          if (tiers[cloverSubscription.value?.data]?.price === undefined) {
            // no subscription found
            setSubscriptionName("n/a");
            setSubscriptionPrice("n/a");
          } else {
            setSubscriptionCardLimit(
              tiers[cloverSubscription.value?.data]?.amount
            );
            setSubscriptionName(cloverSubscription.value?.data);
            setSubscriptionPrice(tiers[cloverSubscription.value?.data]?.price);
            setSubscriptionPeriod(
              tiers[cloverSubscription.value?.data]?.period
            );
          }
        } else {
          // on stripe subscription
          setHasStripeSubscription(true);
          setSubscriptionCardLimit(
            tiers[stripeSubscription.value?.data?.subTier]?.amount
          );
          setSubscriptionName(stripeSubscription.value?.data?.subTier);
          setSubscriptionPrice(
            tiers[stripeSubscription.value?.data?.subTier]?.price
          );
          setSubscriptionPeriod(
            tiers[stripeSubscription.value?.data?.subTier]?.period
          );
        }

        const fields = accountInfoFromDb.value?.data;

        if (fields.companyName) {
          setCompanyName(fields?.companyName);
        } else if (merchantName.value?.data) {
          setCompanyName(merchantName.value?.data);
        }

        if (fields.address) {
          setAddress(fields.address);
        }
        if (fields.city) {
          setCity(fields.city);
        }
        if (fields.state) {
          setState(fields.state);
        }
        if (fields.zip) {
          setZip(fields.zip);
        }
        if (fields.email) {
          setEmail(fields.email);
        }
        if (fields.phone) {
          setPhone(fields.phone);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [id]);

  const handleCancel = async () => {
    let foo = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/stripe/cancelSub/${id}`
    );
    console.log(foo.data);
  };

  const passReset = async (email) => {
    setPasswordModal(true);
    try {
      await resetPassword(email);
    } catch (err) {
      // console.log(err);
    }
  };

  const ResetPasswordModal = () => {
    return (
      <div
        className="test-background"
        onClick={() => {
          setPasswordModal(false);
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="reset-password-modal"
        >
          <VerticalLinearGradientBar height="100%" width="50px" />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div>
              <div
                onClick={() => setPasswordModal(false)}
                style={{ textAlign: "right", cursor: "pointer" }}
              >
                <CloseBtn />
              </div>
              <div
                style={{
                  marginBottom: "20px",
                }}
                className="reset-modal-text"
              >
                Reset Password
              </div>
            </div>
            <div className="reset-modal-text">
              A message has been sent to your E-mail with further instructions.
              If you no longer wish to change your password, disregard that
              E-mail.
            </div>
            <Button
              style={{ width: "90%", height: "48px", marginBottom: "30px" }}
              className="get-balance-button rounded"
              onClick={() => setPasswordModal(false)}
            >
              OKAY
            </Button>
          </div>
        </div>
      </div>
    );
  };

  // only allow cancel if stripe subscription exists
  const CancelSubscriptionModal = () => {
    return (
      <div
        className="test-background"
        onClick={() => {
          setShowCancelModal(false);
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="reset-password-modal"
          style={{ height: "277px" }}
        >
          <VerticalLinearGradientBar height="100%" width="50px" />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div>
              <div
                onClick={() => setShowCancelModal(false)}
                style={{ textAlign: "right", cursor: "pointer" }}
              >
                <CloseBtn />
              </div>
              <div
                style={{
                  marginBottom: "20px",
                }}
                className="reset-modal-text"
              >
                Cancel Subscription
              </div>
            </div>
            <div className="reset-modal-text">
              Are you sure you'd like to cancel your subscription?
            </div>
            <div style={{ display: "flex", margin: "20px 0 20px 20px" }}>
              <button
                className="back-btn"
                style={{ width: "40%", height: "48px", marginRight: "40px" }}
                onClick={() => setShowCancelModal(false)}
              >
                BACK
              </button>
              <Button
                style={{ width: "40%", height: "48px", margin: "0" }}
                className="get-balance-button rounded shadow-none"
                onClick={() => {
                  handleCancel();
                  setShowCancelModal(false);
                }}
              >
                YES
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const AccountProfileModal = () => {
    const [mcompanyName, setMcompanyName] = useState(companyName);
    const [maddress, setMaddress] = useState(address);
    const [mcity, setMcity] = useState(city);
    const [mstate, setMstate] = useState(state);
    const [mzip, setMzip] = useState(zip);
    const [memail, setMemail] = useState(email);
    const [mphone, setMphone] = useState(phone);

    const updateProfileInfo = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/account/account-info/${id}`,
          {
            companyName: mcompanyName,
            address: maddress,
            city: mcity,
            state: mstate,
            zip: mzip,
            email: memail,
            phone: mphone,
          }
        );
        console.log(response.status);
        setCompanyName(mcompanyName);
        setAddress(maddress);
        setCity(mcity);
        setState(mstate);
        setZip(mzip);
        setEmail(memail);
        setPhone(mphone);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div
        className="test-background"
        onClick={() => {
          setAccountProfileModal(false);
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="reset-password-modal"
          style={{ height: "600px", backgroundColor: "#FFFDFA" }}
        >
          <VerticalLinearGradientBar height="100%" width="50px" />
          <div
            style={{
              width: "calc(100% - 50px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div>
              <div
                onClick={() => setAccountProfileModal(false)}
                style={{ textAlign: "right", cursor: "pointer" }}
              >
                <CloseBtn />
              </div>
              <div
                style={{
                  marginBottom: "20px",
                }}
                className="reset-modal-text"
              >
                Update Account Profile
              </div>
            </div>
            <div
              className="profile-modal-all-inputs"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-evenly",
                padding: "0 20px",
              }}
            >
              <input
                onChange={(e) => setMcompanyName(e.target.value)}
                value={mcompanyName}
                placeholder="Profile Name"
              />
              <input
                onChange={(e) => setMaddress(e.target.value)}
                value={maddress}
                placeholder="Address"
              />
              <div className="profile-modal-input-group">
                <input
                  onChange={(e) => setMcity(e.target.value)}
                  value={mcity}
                  style={{ marginBottom: "10px" }}
                  placeholder="City"
                />
                <input
                  onChange={(e) => setMstate(e.target.value)}
                  value={mstate}
                  style={{ marginBottom: "10px" }}
                  placeholder="State"
                />
                <input
                  onChange={(e) => setMzip(e.target.value)}
                  value={mzip}
                  placeholder="Zip Code"
                />
              </div>
              <input
                onChange={(e) => setMemail(e.target.value)}
                value={memail}
                placeholder="E-mail Address"
              />
              <input
                onChange={(e) => setMphone(e.target.value)}
                value={mphone}
                placeholder="Phone Number"
              />
            </div>
            <div className="profile-modal-btn-group">
              <button
                className="back-btn"
                style={{
                  height: "48px",
                  marginRight: "40px",
                  marginBottom: "5px",
                }}
                onClick={() => setAccountProfileModal(false)}
              >
                CANCEL
              </button>
              <Button
                style={{ height: "48px", margin: "0", fontSize: "16px" }}
                className="get-balance-button rounded shadow-none"
                onClick={() => {
                  updateProfileInfo();
                  setAccountProfileModal(false);
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "1150px",
          margin: "0 auto",
          width: "100%",
        }}
      >
        <div className="my-account-heading">My Account</div>
        <div className="my-account-container">
          <div
            style={{
              border: "2px solid #243C57CC",
              borderRadius: "10px",
              height: "520px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="my-account-profile"
          >
            <div
              style={{
                height: "59%",
                width: "84%",
              }}
            >
              <div style={{ fontSize: "20px", marginTop: "30px" }}>
                Account Profile
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "40px 0 20px 0",
                }}
              >
                <div>
                  <strong>{companyName}</strong>
                </div>
                <button
                  onClick={() => setAccountProfileModal(true)}
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    color: "#6894BA",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  UPDATE PROFILE
                </button>
              </div>
              <div style={{ fontSize: "12px" }}>
                <div>{address}</div>
                <div>
                  {city}
                  {city === "" || state === "" ? null : ","} {state} {zip}
                </div>
              </div>
              <div style={{ fontSize: "12px", margin: "20px 0" }}>{email}</div>
              <div style={{ fontSize: "12px" }}>{phone}</div>
            </div>
            <HorizontalDivider />
            <div
              style={{
                height: "40%",
                width: "84%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <strong>Login</strong>
                </div>
                <button
                  onClick={() => {
                    passReset(currentUser.currentUser.email);
                    setPasswordModal(true);
                  }}
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    color: "#6894BA",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  RESET PASSWORD
                </button>
              </div>
              <div style={{ fontSize: "12px", marginTop: "20px" }}>
                {currentUser.currentUser.email}
              </div>
            </div>
          </div>
          <div className="my-account-sub">
            <div
              style={{
                border: "2px solid #243C57CC",
                borderRadius: "10px",
                height: "250px",
                marginBottom: "40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ width: "84%" }}>
                <div style={{ fontSize: "20px", marginTop: "30px" }}>
                  My Subscription
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "40px 0 20px 0",
                  }}
                >
                  <div>
                    <strong>{subscriptionName}</strong>
                  </div>
                  <button
                    onClick={() =>
                      history.push(
                        `${
                          hasStripeSubscription ? "/change-plan" : "/subscribe"
                        }`
                      )
                    }
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "#6894BA",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    UPGRADE SUBSCRIPTION
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "55%",
                  }}
                >
                  <div style={{ fontSize: "12px" }}>
                    {subscriptionCardLimit === -1
                      ? "unlimited"
                      : subscriptionCardLimit}{" "}
                    {subscriptionCardLimit === "" ? null : "cards"}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {subscriptionPrice}
                    {subscriptionPrice === "" || subscriptionPeriod === ""
                      ? null
                      : "/"}
                    {subscriptionPeriod}
                  </div>
                </div>
              </div>
            </div>
            {loading === false && hasStripeSubscription === true ? (
              <div
                style={{
                  border: "2px solid rgba(36, 60, 87, 0.8)",
                  borderRadius: "10px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 22px",
                }}
              >
                <div>Cancel Subscription</div>
                <button
                  onClick={() => setShowCancelModal(true)}
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    color: "#6894BA",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  CANCEL SUBSCRIPTION
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {passwordModal ? <ResetPasswordModal /> : null}
      {showCancelModal ? <CancelSubscriptionModal /> : null}
      {accountProfileModal ? <AccountProfileModal /> : null}
    </AppContainer>
  );
};

export default Profile;

const CloseBtn = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_944_18997)">
        <path
          d="M12.4987 2.08333C6.73828 2.08333 2.08203 6.73958 2.08203 12.5C2.08203 18.2604 6.73828 22.9167 12.4987 22.9167C18.2591 22.9167 22.9154 18.2604 22.9154 12.5C22.9154 6.73958 18.2591 2.08333 12.4987 2.08333ZM17.707 16.2396L16.2383 17.7083L12.4987 13.9687L8.75911 17.7083L7.29037 16.2396L11.0299 12.5L7.29037 8.76041L8.75911 7.29166L12.4987 11.0312L16.2383 7.29166L17.707 8.76041L13.9674 12.5L17.707 16.2396Z"
          fill="#333333"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_944_18997">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const HorizontalDivider = () => {
  return (
    <div
      style={{ backgroundColor: "#d1e6b2", width: "84%", height: "2px" }}
    ></div>
  );
};
