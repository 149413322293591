import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import BackupIcon from '@material-ui/icons/Backup';
import PollIcon from '@material-ui/icons/Poll';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { IoIosArrowBack as Back} from 'react-icons/io';
import { BiDownload as Download } from 'react-icons/bi';
import { CgCloseR as Exit } from 'react-icons/cg';
import { BsInfoCircle as Info } from 'react-icons/bs';
import { MdRadioButtonChecked as Checked } from 'react-icons/md';
import { MdRadioButtonUnchecked as Unchecked } from 'react-icons/md';
import { FaRegTrashAlt as Trash } from 'react-icons/fa';
import { FaRecycle as Recycle } from 'react-icons/fa';
import { BsThreeDots as Dots } from 'react-icons/bs';
import { AiFillPrinter as Printer } from 'react-icons/ai';

const Icons = {
    AccountBalanceIcon,
    PlaylistAddIcon,
    BackupIcon,
    PollIcon,
    FormatListBulletedIcon,
    Back,
    Download,
    Exit,
    Info,
    Checked,
    Unchecked,
    Trash,
    Recycle,
    Dots,
    Printer
}

export default Icons;