import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Table, Alert } from "react-bootstrap";
import { useAuth } from "../../Contexts/AuthContext";
import CurrentDate from "../../Utils/CurrentDate";
import Icons from "../../Images/Icons";
import useMenuVisible from "../../CustomHooks/useMenuVisible";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import AppContainer from "../../Components/AppContainer/AppContainer";
let deleteType;
let deactivateType;

const UsedCards = () => {
  const [pageRefresh, setPageRefresh] = useState(true);
  const [loadingState, setLoadingState] = useState(true);
  const [notifyAlert, setNotifyAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState();
  const [cards, setCards] = useState([]);
  const { currentUser } = useAuth();
  const [modalState, setModalState] = useState(false);
  const [showDeleteSingleCardModal, setShowDeleteSingleCardModal] =
    useState(false);
  const [showDeleteAllCardsModal, setShowDeleteAllCardsModal] = useState(false);
  const [showDeactivateSingleCardModal, setShowDeactivateSingleCardModal] =
    useState(false);
  const [showDeactivateAllCardsModal, setShowDeactivateAllCardsModal] =
    useState(false);
  const { ref, showMenu, setShowMenu } = useMenuVisible(false);
  const [deleteList] = useState([]);
  const [deactivateList] = useState([]);

  const { width } = useWindowDimensions();

  const loading = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: "#282828",
          opacity: 0.7,
          zIndex: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ScaleLoader color="white" />
      </div>
    );
  };

  modalState
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "initial");

  loadingState
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "initial");

  useEffect(() => {
    (async () => {
      const cardsTest = [];
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/used-cards/${currentUser.uid}`
        );
        response.data.usedCards.forEach((card) =>
          cardsTest.push({
            count: response.data.usedCards.indexOf(card) + 1,
            cardNumber: card.cardNumber,
            cardAmount: card.cardAmount,
            dateSold: card.dateSold,
            cardType: card.cardType,
            category: card.category,
            state: card.state,
          })
        );
        setCards(cardsTest);
      } catch (ex) {
        setErrorAlert(
          "Could not connect to the database, please check your internet connection and try again."
        );
      }
    })();
  }, [currentUser.uid]);

  const header = [
    { label: "Count", key: "count" },
    { label: "Number", key: "cardNumber" },
    { label: "Amount", key: "cardAmount" },
    { label: "ActivatedDate", key: "dateSold" },
    { label: "CardType", key: "cardType" },
    { label: "Category", key: "category" },
    { label: "Status", key: "state" },
  ];

  const CSVReport = {
    data: cards,
    headers: header,
    filename: `used-cards-${CurrentDate()}.csv`,
  };

  const csvLink = () => {
    if (cards !== undefined && cards.length !== 0) {
      return (
        <CSVLink
          className="ellipses-menu"
          {...CSVReport}
          onClick={() => setShowMenu(!showMenu)}
        >
          <span>Export</span>
        </CSVLink>
      );
    }
  };

  const deactivateAllButton = () => {
    return (
      <div
        className="ellipses-menu"
        onClick={() => {
          setModalState(true);
          cards.forEach((card) => deactivateList.push(card));
          setShowDeactivateAllCardsModal(true);
        }}
      >
        Deactivate All
      </div>
    );
  };

  const deactivateUsedCardModal = (header, deactivateType) => {
    return (
      <>
        <div
          className="modal-background"
          onClick={() => {
            setModalState(false);
            setShowDeactivateSingleCardModal(false);
            setShowDeactivateAllCardsModal(false);
            deactivateList.length = 0;
          }}
        />
        <div className="delete-used-cards-modal">
          <h5>{header}</h5>
          <section>
            <h6>Warning:</h6>
            <p>
              Deactivating used cards is an action that cannot be undone. Please
              make sure this is what you want to do before proceeding.
            </p>
          </section>
          <button
            onClick={async () => {
              setLoadingState(true);
              deactivateType === "one"
                ? await deactivateCard()
                : await deactivateAllUsedCards();
              setShowMenu(false);
              setTimeout(() => {
                setModalState(false);
                refreshCards();
                setLoadingState(false);
                deactivateList.length = 0;
              }, 1000);
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              setShowDeactivateSingleCardModal(false);
              setShowDeactivateAllCardsModal(false);
              setModalState(false);
              deactivateList.length = 0;
            }}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const deactivateCard = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/reports/deactivate-used-cards/${currentUser.uid}`,
        { deactivateList }
      );
      setNotifyAlert("Used card successfully deactivated");
    } catch (ex) {
      setErrorAlert("An error occurred, please try again.");
    } finally {
      setTimeout(() => setShowDeactivateSingleCardModal(false), 1000);
    }
  };

  const deactivateAllUsedCards = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/reports/deactivate-used-cards/${currentUser.uid}`,
        { deactivateList }
      );
      setNotifyAlert("All used cards successfully deactivated");
    } catch (ex) {
      setErrorAlert("An error occurred, please try again");
    } finally {
      setTimeout(() => setShowDeactivateAllCardsModal(false), 1000);
    }
  };

  const deleteAllButton = () => {
    return (
      <div
        onClick={() => {
          setModalState(true);
          cards.forEach((card) => deleteList.push(card));
          setShowDeleteAllCardsModal(true);
          setShowMenu(!showMenu);
        }}
      >
        <span className="ellipses-menu">Delete All</span>
      </div>
    );
  };

  const deleteUsedCardModal = (header, deleteType) => {
    return (
      <>
        <div
          className="modal-background"
          onClick={() => {
            setModalState(false);
            setShowDeleteSingleCardModal(false);
            setShowDeleteAllCardsModal(false);
            deleteList.length = 0;
          }}
        />
        <div className="delete-used-cards-modal">
          <h5>{header}</h5>
          <section>
            <h6>Warning:</h6>
            <p>
              Any physical cards you delete here should be destroyed in order to
              prevent the possibility of a card number being assigned to two
              active cards.
            </p>
          </section>
          <button
            onClick={async () => {
              setLoadingState(true);
              deleteType === "one"
                ? await deleteCard()
                : await deleteAllUsedCards();
              setShowMenu(false);
              setTimeout(() => {
                setModalState(false);
                refreshCards();
                setLoadingState(false);
                deleteList.length = 0;
              }, 1000);
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              setShowDeleteSingleCardModal(false);
              setShowDeleteAllCardsModal(false);
              setModalState(false);
              deleteList.length = 0;
            }}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const deleteCard = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/reports/delete-used-cards/${currentUser.uid}`,
        { data: { deleteList } }
      );
      setNotifyAlert("Used card successfully deleted");
    } catch (ex) {
      setErrorAlert("An error occurred, please try again.");
    } finally {
      setTimeout(() => setShowDeleteSingleCardModal(false), 1000);
    }
  };

  const deleteAllUsedCards = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/reports/delete-used-cards/${currentUser.uid}`,
        { data: { deleteList } }
      );
      setNotifyAlert("All used cards successfully deleted");
    } catch (ex) {
      setErrorAlert("An error occurred, please try again.");
    } finally {
      setTimeout(() => setShowDeleteAllCardsModal(false), 1000);
    }
  };

  const displayMenuDots = () => {
    return (
      <div className="report-ellipses" ref={ref}>
        <Icons.Dots onClick={() => setShowMenu(!showMenu)} />
        {showMenu && (
          <div
            style={{
              position: "absolute",
              top: 20,
              right: 0,
              width: "fit-content",
              border: "solid 2px gray",
              borderRadius: "5px",
              backgroundColor: "#4d4d4d",
              padding: "10px",
              marginRight: "20px",
              color: "white",
            }}
          >
            {csvLink()}
            <hr style={{ margin: "5px 0" }} />
            {deleteAllButton()}
            <hr style={{ margin: "5px 0" }} />
            {deactivateAllButton()}
          </div>
        )}
      </div>
    );
  };

  const refreshCards = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/reports/used-cards/${currentUser.uid}`
      );
      setCards(response.data.usedCards);
    } catch (ex) {
      setErrorAlert("Unable to refresh cards, please try again.");
    }
  };

  const getCards = () => {
    if (pageRefresh && loadingState) {
      setTimeout(() => {
        setLoadingState(false);
        setPageRefresh(false);
      }, 1000);
    }

    if (cards !== undefined && cards.length > 0) {
      return width < 900
        ? cards.map((card, index) => {
            return (
              <div key={index} className="activeCard">
                <div className="active-card-number">{`Card Number: ${card.cardNumber}`}</div>
                <div>{`Balance: $${card.cardAmount}`}</div>
                <div className="active-card-status">
                  Status:{" "}
                  {`${card.state[0].toUpperCase()}${card.state.slice(1)}`}
                </div>
                <div
                  className="deleteBtn"
                  onClick={() => {
                    deleteList.push(card);
                    setShowDeleteSingleCardModal(true);
                    setModalState(true);
                  }}
                >
                  Delete
                </div>
                <div
                  style={{ color: "goldenrod", width: "fit-content" }}
                  onClick={() => {
                    deactivateList.push(card);
                    setShowDeactivateSingleCardModal(true);
                    setModalState(true);
                  }}
                >
                  Deactivate
                </div>
                <hr />
              </div>
            );
          })
        : cards.length > 0 && (
            <Table className="activeCard" striped bordered hover>
              <thead>
                <tr>
                  <th>Card Number</th>
                  <th>Status</th>
                  <th>Balance</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {cards.map((card, index) => {
                  return (
                    <tr key={index}>
                      <td>{card.cardNumber.toUpperCase()}</td>
                      <td>{card.state}</td>
                      <td>{`$${card.cardAmount}`}</td>
                      <td className="tableActions">
                        <div
                          className="deleteBtn"
                          onClick={() => {
                            deleteList.push(card);
                            setShowDeleteSingleCardModal(true);
                            setModalState(true);
                          }}
                        >
                          <Icons.Trash />
                        </div>
                        <div
                          style={{ color: "goldenrod", width: "fit-content" }}
                          onClick={() => {
                            deactivateList.push(card);
                            setShowDeactivateSingleCardModal(true);
                            setModalState(true);
                          }}
                        >
                          <Icons.Recycle />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          );
    } else {
      return <div className="no-cards-message">No used cards found</div>;
    }
  };

  return (
    <AppContainer>
      <div className="used-cards-report"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1100px" }}>
          {cards.length > 0 && displayMenuDots()}
          {loadingState && loading()}
          <div
            className="active-card-buttons"
            style={{
              margin: "15px 0px",
            }}
          ></div>
          <h2>Used Cards</h2>
          <p>All cards that have been sold without a remaining balance</p>
          {notifyAlert && (
            <Alert variant="success" className="success">
              {notifyAlert}
            </Alert>
          )}
          {errorAlert && (
            <Alert variant="danger" className="error">
              {errorAlert}
            </Alert>
          )}
          {cards.length > 0 ? (
            cards.length === 1 ? (
              <div className="total-active-cards">{`${cards.length} card total`}</div>
            ) : (
              <div className="total-active-cards">{`${cards.length} cards total`}</div>
            )
          ) : (
            <div />
          )}
          {getCards()}
          {showDeleteSingleCardModal &&
            deleteUsedCardModal("Delete this used card?", (deleteType = "one"))}
          {showDeleteAllCardsModal &&
            deleteUsedCardModal("Delete all used cards?", (deleteType = "all"))}
          {showDeactivateSingleCardModal &&
            deactivateUsedCardModal(
              "Deactivate this used card?",
              (deactivateType = "one")
            )}
          {showDeactivateAllCardsModal &&
            deactivateUsedCardModal(
              "Deactivate all used cards?",
              (deactivateType = "all")
            )}
        </div>
      </div>
    </AppContainer>
  );
};

export default UsedCards;
