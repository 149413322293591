import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../Contexts/AuthContext";
import { Loading } from "../../Components";
import { CSVLink } from "react-csv";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import { useRecoilState } from "recoil";
import { CardPrinterState } from "../../Atoms/CardPrinterAtoms";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import AppContainer from "../../Components/AppContainer/AppContainer";

const sortDates = (dates) => {
  return dates.sort(function (a, b) {
    // Convert the dates to JavaScript Date objects
    var dateA = new Date(a);
    var dateB = new Date(b);

    // Compare the dates and return -1 if the first date is smaller, 1 if the second date is smaller, or 0 if they are equal
    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  });
};

const removeArchiveData = (inputStr) => {
  const endIndex = inputStr.indexOf("ARCHIVE");
  return inputStr.substring(0, endIndex);
};

const GeneratorReport = () => {
  const [, setCardPrinterState] = useRecoilState(CardPrinterState);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [cards, setCards] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const { currentUser } = useAuth();
  const [alert, setAlert] = useState("");
  const { width } = useWindowDimensions();

  const location = useLocation();
  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const date = searchParams.get("date");
  const batchId = searchParams.get("batchId");

  useEffect(() => {
    if (date !== undefined && batchId !== undefined) {
      if (date !== null && batchId !== null) {
        console.log("query params date is: ", date);
        console.log("query params batchId is: ", batchId);
        setSelectedBatch(batchId);
        getCardsByDateFromQuery(date);
      }
    }
  }, [batchId, date]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/generated-dates/${currentUser.uid}`
        );
        setDates(response.data.dates);
      } catch (err) {
        setAlert(`An error occurred: ${err.message}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentUser.uid]);

  const header = [
    { label: "Number", key: "cardNumber" },
    { label: "Amount", key: "cardAmount" },
    { label: "ActivatedDate", key: "activatedDate" },
    { label: "CardType", key: "cardType" },
    { label: "Category", key: "category" },
    { label: "Generated Date", key: "generatedDate" },
    { label: "Card Status", key: "state" },
  ];

  const CSVReport = {
    data: cards,
    headers: header,
    filename: `cards-generated-on-${selectedDate.replaceAll(" ", "-")}.csv`,
  };

  const getCardsByDateFromQuery = async (date) => {
    resetCards(); /** empties out the cards array before executing the rest of this function*/
    setLoading(true);
    setSelectedDate(date);
    const thisDate = date;

    try {
      /** calls database and stores http response inside response variable */
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/reports/generated-cards/${currentUser.uid}/${thisDate}`
      );

      // todo take in date & batchUid as query param
      console.log("using date ", thisDate);
      console.log("resp", response.data);
      let tempBatchUids = [];
      let temp = [];
      response.data.cards
        .sort((a, b) =>
          a.state === b.state ? 0 : a.state === "inactive" ? 1 : -1
        )
        .forEach((card) => {
          if (card.cardNumber?.includes("ARCHIVE")) {
            card.cardNumber = removeArchiveData(card.cardNumber);
          }
          temp.push({
            cardNumber: card.cardNumber,
            cardAmount: card.cardAmount,
            activatedDate: card.activatedDate,
            cardType: card.cardType,
            category: card.category,
            generatedDate: card.generatedDate,
            uploadedDate: card.uploadedDate,
            state: card.state,
            batchUid: card.batchUid,
          });
          if (card.batchUid) {
            tempBatchUids.push(card.batchUid);
          }
        });
      setBatches([...new Set(tempBatchUids)]);
      setCards(temp);
    } catch (err) {
      console.log(`Something went wrong: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  /** called when a date is selected to see cards that were generated on the selected date */
  const getCardsByDate = async (e, date) => {
    resetCards(); /** empties out the cards array before executing the rest of this function*/
    setLoading(true);
    e.preventDefault();
    setSelectedDate(date);
    const thisDate = date;

    try {
      /** calls database and stores http response inside response variable */
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/reports/generated-cards/${currentUser.uid}/${thisDate}`
      );

      console.log("using date ", thisDate);
      console.log("resp", response.data);
      let tempBatchUids = [];
      let temp = [];
      response.data.cards
        .sort((a, b) =>
          a.state === b.state ? 0 : a.state === "inactive" ? 1 : -1
        )
        .forEach((card) => {
          if (card.cardNumber?.includes("ARCHIVE")) {
            card.cardNumber = removeArchiveData(card.cardNumber);
          }
          temp.push({
            cardNumber: card.cardNumber,
            cardAmount: card.cardAmount,
            activatedDate: card.activatedDate,
            cardType: card.cardType,
            category: card.category,
            generatedDate: card.generatedDate,
            uploadedDate: card.uploadedDate,
            state: card.state,
            batchUid: card.batchUid,
          });
          if (card.batchUid) {
            tempBatchUids.push(card.batchUid);
          }
        });
      setBatches([...new Set(tempBatchUids)]);
      setCards(temp);
    } catch (err) {
      console.log(`Something went wrong: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  /** remove cards from cards array */
  const resetCards = () => setCards([]);

  const ButtonsContainer = ({ small = false }) => {
    return (
      <div
        style={{
          display: cards !== undefined && cards.length !== 0 ? "flex" : "none",
          width: small ? "100%" : "600px",
          justifyContent: "space-between",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        <div className="active-card-buttons">
          {!loading ? (
            <CSVLink
              {...CSVReport}
              style={{
                width: small ? "142px" : "250px",
                height: small ? "32px" : "44px",
              }}
              className="download-csv-btn"
            >
              <span>DOWNLOAD CSV</span>
            </CSVLink>
          ) : null}
        </div>
        <Button
          style={{
            width: small ? "142px" : "250px",
            height: small ? "32px" : "44px",
            fontSize: "16px",
          }}
          className="shadow-none reports-button"
          onClick={() => {
            setCardPrinterState(
              cards.filter((card) => {
                // only sending selected batch cards to printer
                if (batches.length > 1) {
                  return card.batchUid?.toString() === selectedBatch;
                } else {
                  return card;
                }
              })
            );
            history.push("/card-printer");
          }}
        >
          PRINT CARDS
        </Button>
      </div>
    );
  };

  const getCards = () => {
    if (cards !== undefined && cards.length > 0) {
      return width < 900 ? (
        <div className="created-cards-container">
          {cards
            .filter((card) => {
              // only filter if there's more than 1 batch
              if (batches.length > 1) {
                return card.batchUid?.toString() === selectedBatch;
              } else {
                return card;
              }
            })
            .map((card, index) => {
              return (
                <div key={index} className="dateCard">
                  <div className="dateCardNumber">{`Card Number: ${card.cardNumber}`}</div>
                  {card.cardAmount && (
                    <div>{`Balance: $${card.cardAmount}`}</div>
                  )}
                  {card.activatedDate && (
                    <div>{`Activated Date: ${card.activatedDate}`}</div>
                  )}
                  {card.cardType && <div>{`Type: ${card.cardType}`}</div>}
                  {card.category && <div>{`Category: ${card.category}`}</div>}
                  {card.dateSold && <div>{`Sold Date: ${card.dateSold}`}</div>}
                  <div>{`Generated Date: ${card.generatedDate}`}</div>
                  {card.state === "active" ? (
                    <div
                      style={{ color: "green" }}
                    >{`State: ${card.state[0].toUpperCase()}${card.state.slice(
                      1
                    )}`}</div>
                  ) : (
                    <div>{`State: ${card.state[0].toUpperCase()}${card.state.slice(
                      1
                    )}`}</div>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <div
          style={{
            border: "1px solid lightgray",
            // width: "80%",
            height: "356px",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table className="table-generator-history">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr style={{ backgroundColor: "#d2dee7" }}>
                <th>Card Number</th>
                <th>Status</th>
                <th>Generated Date</th>
              </tr>
            </thead>
            <tbody>
              {cards
                .filter((card) => {
                  // only filter if there's more than 1 batch else show all cards
                  if (batches.length > 1) {
                    return card.batchUid?.toString() === selectedBatch;
                  } else {
                    return card;
                  }
                })
                .map((card, index) => {
                  return (
                    <tr key={index}>
                      <td>{card.cardNumber.toUpperCase()}</td>
                      <td>{`${card.state[0].toUpperCase()}${card.state.slice(
                        1
                      )}`}</td>
                      <td>{card.generatedDate}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{ width: width < 450 ? "100%" : "80%", maxWidth: "1100px" }}
        >
          <div style={{ color: "red" }}>{alert}</div>
          {dates !== undefined && dates.length !== 0 ? (
            <>
              <h2 style={{ textAlign: width < 450 ? "center" : "" }}>
                {date ? null : "Created Cards"}
              </h2>
              <div
                style={{
                  margin: "30px 0 45px 0",
                  textAlign: width < 450 ? "center" : "",
                  display:
                    cards !== undefined && cards.length !== 0
                      ? "block"
                      : "none",
                }}
              >
                {date
                  ? null
                  : "Cards that were created with our number generator. When they were created, you were given the option to print cards or download CSV. These options are still available here, but be aware you may have already printed these cards."}
              </div>
            </>
          ) : (
            <div className="no-cards-message">No Created Cards</div>
          )}
          {width < 900 ? <ButtonsContainer small={true} /> : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // width: "80%",
              marginBottom: "14px",
            }}
          >
            {cards.length !== 0 && (
              <div className="total-generator-cards">{`${
                cards.filter((card) => {
                  // only filter if there's more than 1 batch
                  if (batches.length > 1) {
                    return card.batchUid?.toString() === selectedBatch;
                  } else {
                    return card;
                  }
                }).length
              } cards`}</div>
            )}
            {date ? null : (
              <select
                name=""
                id=""
                className="dateSelect"
                onChange={(e) => getCardsByDate(e, e.target.value)}
                value={selectedDate}
              >
                <option value="" disabled>
                  Filter by Date
                </option>
                {dates != null &&
                  sortDates(dates).map((date, index) => {
                    return (
                      <option href="" key={index}>
                        {date}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
          {batches.length > 1 && selectedDate !== "" ? (
            <div style={{ textAlign: "right", marginBottom: "20px" }}>
              {date ? null : (
                <select
                  name=""
                  id=""
                  className="dateSelect"
                  onChange={(e) => setSelectedBatch(e.target.value)}
                  value={selectedBatch}
                >
                  <option value="" disabled>
                    Select a batch
                  </option>
                  {batches.map((batch, index) => {
                    return (
                      <option href="" key={index}>
                        {batch}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          ) : null}
          {loading && <div>{Loading()}</div>}
          {getCards()}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {width >= 900 ? <ButtonsContainer /> : null}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default GeneratorReport;
