import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import AppContainer from "../../Components/AppContainer/AppContainer";

const Reports = () => {
  let history = useHistory();

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "1150px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <div className="btn-container">
          <Button
            className="shadow-none reports-button reports-page-btn"
            onClick={() => history.push("/reports/all-active-cards")}
          >
            Active Cards
          </Button>
          <Button
            className="shadow-none reports-button reports-page-btn"
            onClick={() => history.push("/reports/all-inactive-cards")}
          >
            Inactive Cards
          </Button>
          <Button
            className="shadow-none reports-button reports-page-btn"
            onClick={() => history.push("/reports/bulk-upload-history")}
          >
            Imported Cards
          </Button>
          <Button
            className="shadow-none reports-button reports-page-btn"
            onClick={() => history.push("/reports/number-generator-history")}
          >
            Created Cards
          </Button>
          <Button
            className="shadow-none reports-button reports-page-btn"
            onClick={() => history.push("/reports/usage-log")}
          >
            Usage Log
          </Button>
          <Button
            className="shadow-none reports-button reports-page-btn"
            onClick={() => history.push("/reports/location-redemption")}
          >
            Location Redemption
          </Button>
        </div>
      </div>
    </AppContainer>
  );
};

export default Reports;
