import React, { useState, useEffect } from "react";
import axios from "axios";
import { Loading } from "../../Components";
import { useAuth } from "../../Contexts/AuthContext";
import CurrentDate from "../../Utils/CurrentDate";
import Icons from "../../Images/Icons";
import { CSVLink } from "react-csv";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import AppContainer from "../../Components/AppContainer/AppContainer";
import { Button } from "react-bootstrap";

const ActiveCardsReport = () => {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const { currentUser } = useAuth();
  const { width } = useWindowDimensions();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/active-cards/${currentUser.uid}`
        );

        let temp = [];
        if (response.data.cards !== undefined) {
          response.data.cards.forEach((card) =>
            temp.push({
              cardNumber: card.cardNumber,
              cardAmount: card.cardAmount,
              dateSold: card.dateSold,
              cardType: card.cardType,
              category: card.category,
              state: card.state,
            })
          );
        }

        setCards(temp);
        setLoading(false);
      } catch (ex) {
        throw new Error(ex.message);
      }
    })();
  }, [currentUser.uid]);

  const header = [
    { label: "Number", key: "cardNumber" },
    { label: "Amount", key: "cardAmount" },
    { label: "ActivatedDate", key: "dateSold" },
    { label: "CardType", key: "cardType" },
    { label: "Category", key: "category" },
    { label: "Status", key: "state" },
  ];

  const CSVReport = {
    data: cards,
    headers: header,
    filename: `active-cards-${CurrentDate()}.csv`,
  };

  const ExportButton = ({ small = false }) => {
    if (cards !== undefined && cards.length !== 0) {
      return (
        <div
          style={{
            display: small ? "" : "flex",
            margin: small ? "" : "40px 0 20px 0",
            justifyContent: small ? "" : "flex-end",
          }}
        >
          <Button
            style={{
              height: small ? "30px" : "44px",
              width: small ? "100%" : "",
              marginBottom: small ? "20px" : "",
              padding: 0,
            }}
            className="shadow-none reports-button"
          >
            <CSVLink
              style={{
                width: small ? "" : "200px",
                height: "44px",
                textDecoration: "none",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
              }}
              {...CSVReport}
            >
              <span style={{ marginRight: "10px" }}>EXPORT</span>
              <Icons.Download />
            </CSVLink>
          </Button>
        </div>
      );
    }

    return <></>;
  };

  const GetCards = () => {
    if (cards !== undefined && cards.length !== 0) {
      return width < 900 ? (
        cards.map((card, index) => {
          return (
            <div key={index} className="dateCard">
              <div className="active-card-number">{`Card Number: ${card.cardNumber}`}</div>
              <div>{`Balance: $${card.cardAmount}`}</div>
              {card.dateSold && <div>{`Activated Date: ${card.dateSold}`}</div>}
              {card.cardType && <div>{`Card Type: ${card.cardType}`}</div>}
              {card.category && <div>{`Category: ${card.category}`}</div>}
              <div className="active-card-status">
                Status: {`${card.state[0].toUpperCase()}${card.state.slice(1)}`}
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            border: "1px solid lightgray",
            width: "100%",
            height: "356px",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table className="table-generator-history">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr style={{ backgroundColor: "#d2dee7" }}>
                <th>Card Number</th>
                <th>Status</th>
                <th>Balance</th>
                <th>Activated Date</th>
                <th>Card Type</th>
              </tr>
            </thead>
            <tbody>
              {cards.map((card, index) => {
                return (
                  <tr key={index}>
                    <td>{card.cardNumber.toUpperCase()}</td>
                    <td>Active</td>
                    <td>{card.cardAmount}</td>
                    <td>{card.dateSold}</td>
                    <td>{card.cardType}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return <div className="no-cards-message">No active cards found</div>;
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "80%", maxWidth: "1100px" }}>
          <h2 className="active-cards-header">Active Cards</h2>
          {cards.length > 0 ? (
            <div
              className="active-cards-header"
              style={{ margin: "30px 0 45px 0" }}
            >
              All cards that have been sold with a remaining balance
            </div>
          ) : null}
          {width < 900 && !loading ? <ExportButton small={true} /> : null}
          {cards.length !== 0 && (
            <div
              style={{ marginBottom: "14px" }}
              className="active-cards-total"
            >{`${cards.length} cards`}</div>
          )}
          {loading ? <Loading /> : null}
          {!loading ? <GetCards /> : null}
          {width >= 900 && !loading ? <ExportButton /> : null}
        </div>
      </div>
    </AppContainer>
  );
};

export default ActiveCardsReport;
