import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loading = () => {
  return (
    <div className="loading">
      <ScaleLoader color="white" />
    </div>
  );
};

export default Loading;
