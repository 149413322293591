import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import { Button } from "react-bootstrap";
import axios from "axios";
import RandomString from "randomstring";
import CurrentDate from "../../Utils/CurrentDate";
import AppContainer from "../../Components/AppContainer/AppContainer";

const Generator = () => {
  const history = useHistory();
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [prefix, setPrefix] = useState("");

  const length = 16;
  const { currentUser } = useAuth();

  // changes prefix letter input to uppercase
  const handlePrefixChange = (e) => {
    let value = e.target.value.toUpperCase().replace(/[\W_]+/g, "");
    setPrefix(value);
  };

  // limits quantity input to numbers only
  const handleQuantityChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setQuantity(value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const generateCardNumbersAndGoToReports = async () => {
    let currentCards = [];
    let numbers = [];
    let digits = length - prefix.length;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/reports/all-cards/${currentUser.uid}`
      );
      currentCards = response?.data?.cards?.map((card) => card.cardNumber);
    } catch (error) {
      setAlert(error.message);
      return;
    }

    numbers = generateRandomStringsArray(
      prefix,
      digits,
      quantity,
      currentCards
    );

    let temp = [];
    numbers.forEach((number) =>
      temp.push({
        count: numbers.indexOf(number) + 1,
        cardNumber: number,
        quantity: quantity,
        generatedDate: CurrentDate(),
        state: "inactive",
      })
    );

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/generator/add-cards/${currentUser.uid}`,
        { cards: temp }
      );

      const { batchUid, fullDate } = data;
      history.push(
        `/reports/number-generator-history?date=${fullDate}&batchId=${batchUid}`
      );
    } catch (error) {
      setAlert(error.message);
      setLoading(false);
    }
  };

  const newHandleSubmit = async (e) => {
    e.preventDefault();
    setAlert("");
    setLoading(true);

    // make sure prefix is 0 to 4 characters long
    if (prefix.length > 4) {
      setAlert("Prefix cannot be more than 4 characters long");
      setLoading(false);
      return scrollToTop();
    }

    // make sure prefix does not start with 0
    if (prefix[0] == 0) {
      setAlert("Prefix cannot start with a zero");
      setLoading(false);
      return scrollToTop();
    }

    // make sure card quantity is between 1 and 1000
    if (quantity < 1 || quantity > 1000 || quantity === "") {
      setAlert("Please enter a valid card quantity");
      setLoading(false);
      return scrollToTop();
    }

    generateCardNumbersAndGoToReports();
  };

  const generateRandomStringsArray = (
    prefix,
    stringLength,
    quantity,
    currentCards
  ) => {
    let array = [];

    for (let i = 0; i < quantity; i++) {
      let randString =
        prefix +
        RandomString.generate({
          length: stringLength,
          capitalization: "uppercase",
          readable: true, // exclude poorly readable chars: 0OIl
        });

      // only add a string that doesn't currently exist in array[] & currentCards[]
      while (
        array?.includes(randString) ||
        currentCards?.includes(randString)
      ) {
        randString =
          prefix +
          RandomString.generate({
            length: stringLength,
            capitalization: "uppercase",
            readable: true, // exclude poorly readable chars: 0OIl
          });
      }
      array.push(randString);
    }

    return array;
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        className="generator"
      >
        <div
          style={{
            maxWidth: "900px",
            margin: "0px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 className="create-cards-heading">Create Cards</h2>
          <div className="create-cards-help-text">
            Enter the quantity of cards you would like to print and our card
            generator will produce unique numbers for your gift cards.
          </div>
          {alert ? (
            <div className="alert-container">
              <WarningIcon />
              {alert}
            </div>
          ) : null}

          <div
            style={{
              width: "90%",
              maxWidth: "500px",
              height: "400px",
              backgroundColor: "#EDECEA",
              borderRadius: "10px",
            }}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "100%",
              }}
              onSubmit={newHandleSubmit}
            >
              <div
                style={{ fontSize: "20px", fontWeight: "500", width: "85%" }}
              >
                Number Generator
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{
                      width: "85%",
                      height: "46px",
                      border: "1px solid #dadada",
                      paddingLeft: "8px",
                    }}
                    type="text"
                    value={prefix}
                    maxLength={4}
                    placeholder="Prefix (Optional)"
                    className="user-input shadow-none"
                    onChange={handlePrefixChange}
                    disabled={loading}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      width: "85%",
                      fontSize: "13px",
                      paddingLeft: "8px",
                    }}
                  >
                    1 to 4 characters and cannot start with a zero
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{
                      width: "85%",
                      height: "46px",
                      border: "1px solid #dadada",
                      paddingLeft: "8px",
                    }}
                    className="user-input shadow-none"
                    onChange={handleQuantityChange}
                    value={quantity}
                    maxLength={4}
                    disabled={loading}
                    placeholder="Card Quantity"
                  />
                  <div
                    style={{
                      textAlign: "left",
                      width: "85%",
                      paddingLeft: "8px",
                      fontSize: "13px",
                    }}
                  >
                    Quantity of card numbers you want to generate. 1 - 1,000
                    Cards
                  </div>
                </div>
              </div>
              <Button
                style={{
                  width: "85%",
                  margin: "0",
                }}
                type="submit"
                className="shadow-none get-balance-button generate-btn"
                disabled={loading}
              >
                GENERATE CARD NUMBERS
              </Button>
            </form>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default Generator;

const WarningIcon = () => {
  return (
    <div style={{ paddingRight: "10px" }}>
      <svg
        width="22"
        height="37"
        viewBox="0 0 22 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.084 21.25H11.9173V23.0833H10.084V21.25ZM10.084 13.9167H11.9173V19.4167H10.084V13.9167ZM10.9915 9.33334C5.93148 9.33334 1.83398 13.44 1.83398 18.5C1.83398 23.56 5.93148 27.6667 10.9915 27.6667C16.0607 27.6667 20.1673 23.56 20.1673 18.5C20.1673 13.44 16.0607 9.33334 10.9915 9.33334ZM11.0007 25.8333C6.94898 25.8333 3.66732 22.5517 3.66732 18.5C3.66732 14.4483 6.94898 11.1667 11.0007 11.1667C15.0523 11.1667 18.334 14.4483 18.334 18.5C18.334 22.5517 15.0523 25.8333 11.0007 25.8333Z"
          fill="#D32F2F"
        />
      </svg>
    </div>
  );
};
