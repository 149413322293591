import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import AppContainer from "../../Components/AppContainer/AppContainer";
import axios from "axios";
import { Button } from "react-bootstrap";
import Icons from "../../Images/Icons";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

// Using 'YYYY-MM-DD' date format as it is the default for HTML's <input type="date">.
// Opted for the native HTML date picker due to compatibility issues with third-party
// date pickers in an older version of React.

// This function converts a Date object or Unix timestamp to a formatted date string (YYYY-MM-DD)
// ex: 1716300653770 Unix timestamp to "2024-05-21"
const formatDate = (input) => {
  const timestamp = Number(input); // Convert string to number
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// takes in 2 dates in the format of YYYY-MM-DD
// and returns in format of "M/D/YYYY - M/D/YYYY"
const formatDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Function to format date as "M/D/YYYY"
  const formatDateMDY = (date) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return `${formatDateMDY(start)} - ${formatDateMDY(end)}`;
};

// Parsing date string in "d MMM yyyy" format
// because cocoa-giftcards-android code uses DateFormat df = new SimpleDateFormat("d MMM yyyy");
// and database stores dates in this format.
const parseDbDateString = (dateString) => {
  const monthMapping = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const [day, month, year] = dateString.split(" ");
  const monthIndex = monthMapping[month];
  return new Date(year, monthIndex, day);
};

const formatInDollars = (total) => {
  return (total / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

const LocationRedemption = () => {
  const [allLocationsData, setAllLocationsData] = useState([]);
  const [selectedLocationData, setSelectedLocationData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const [redeemData, setRedeemData] = useState([]);
  const [sellData, setSellData] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/location-redemption/${currentUser.uid}`
        );
        console.log(data);

        setSellData(data.sellData);
        setRedeemData(data.redeemData);
      } catch (err) {
        console.log(`An error occurred: ${err.message}`);
      }
    })();
  }, [currentUser.uid]);

  const csvLinkRef = useRef(null);
  const csvLinkSelectedLocationRef = useRef(null);
  let history = useHistory();

  useEffect(() => {
    const buildAllLocationsSalesAndRedemptionsData = () => {
      if (!startDate || !endDate) {
        return;
      }

      const filteredSellData = sellData.filter((element) => {
        const elementDate = formatDate(parseDbDateString(element.date));
        return elementDate >= startDate && elementDate <= endDate;
      });

      const filteredRedeemData = redeemData.filter((element) => {
        const elementDate = formatDate(element.timeStamp);
        return elementDate >= startDate && elementDate <= endDate;
      });

      let allLocationsDataObj = {};

      const dateRange = formatDateRange(startDate, endDate);

      filteredSellData.forEach((element) => {
        if (allLocationsDataObj[element.location]) {
          allLocationsDataObj[element.location].amountSold += parseInt(
            element.cardSoldAmount
          );
        } else {
          allLocationsDataObj[element.location] = {
            location: element.location,
            amountSold: parseInt(element.cardSoldAmount),
            amountRedeemed: 0,
            dateRange,
          };
        }
      });

      filteredRedeemData.forEach((element) => {
        if (allLocationsDataObj[element.redeemLocation]) {
          allLocationsDataObj[element.redeemLocation].amountRedeemed +=
            parseInt(element.redeemAmount);
        } else {
          allLocationsDataObj[element.redeemLocation] = {
            location: element.redeemLocation,
            amountSold: 0,
            amountRedeemed: parseInt(element.redeemAmount),
            dateRange,
          };
        }
      });

      const allLocationsDataArray = Object.values(allLocationsDataObj);

      let totalSales = 0;
      let totalRedemptions = 0;

      allLocationsDataArray.forEach((el) => {
        totalSales += el.amountSold;
        totalRedemptions += el.amountRedeemed;
      });

      // append totals object to end of array so that it shows at the bottom of the table
      setAllLocationsData([
        ...allLocationsDataArray,
        {
          location: "Total",
          amountSold: totalSales,
          amountRedeemed: totalRedemptions,
          dateRange: dateRange,
        },
      ]);
    };
    buildAllLocationsSalesAndRedemptionsData();
  }, [startDate, endDate]);

  useEffect(() => {
    const buildSelectedLocationRedemptionsData = () => {
      if (!startDate || !endDate || !selectedLocation) {
        return;
      }

      const filteredRedeemData = redeemData.filter((element) => {
        const elementDate = formatDate(element.timeStamp);
        return (
          elementDate >= startDate &&
          elementDate <= endDate &&
          element.redeemLocation === selectedLocation
        );
      });

      let selectedLocationDataObj = {};

      filteredRedeemData.forEach((element) => {
        if (selectedLocationDataObj[element.purchaseLocation]) {
          selectedLocationDataObj[element.purchaseLocation].amountRedeemed +=
            parseInt(element.redeemAmount);
        } else {
          selectedLocationDataObj[element.purchaseLocation] = {
            originLocation: element.purchaseLocation,
            amountRedeemed: parseInt(element.redeemAmount),
          };
        }
      });

      setSelectedLocationData(Object.values(selectedLocationDataObj));
    };
    buildSelectedLocationRedemptionsData();
  }, [selectedLocation, startDate, endDate]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;

    // Ensure start date is <= end date
    if (new Date(newStartDate) > new Date(endDate)) {
      setEndDate(newStartDate);
    }
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;

    // Ensure end date is >= start date
    if (new Date(newEndDate) < new Date(startDate)) {
      setStartDate(newEndDate);
    }
    setEndDate(newEndDate);
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "1150px",
          margin: "0 auto",
          width: "80%",
          height: "100%",
          paddingTop: "30px",
        }}
      >
        <h2 style={{ width: "100%", margin: "0 0 20px 0px" }}>
          {selectedLocation ? selectedLocation : "Location Redemption Report"}
        </h2>
        {selectedLocation ? (
          <div
            style={{
              width: "100%",
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "20px",
            }}
          >
            {formatDateRange(startDate, endDate)}
          </div>
        ) : null}
        <div style={{ width: "100%", marginBottom: "20px", fontWeight: "600" }}>
          {selectedLocation
            ? "On the table below, “Origin Location” displays where gift cards were purchased, “Amount Redeemed” displays the dollar amount redeemed at the location listed above."
            : "Select location from the table to view where redeemed gift cards were purchased."}
        </div>
        <div
          style={{
            fontSize: "small",
            color: "red",
            textAlign: "left",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <strong>Warning</strong>: This report includes only gift cards sold or
          redeemed using version 1.1.8 or later.
          <br />
          Transactions from earlier versions are not covered, which may result
          in apparent inaccuracies in the data presented.
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {!selectedLocation ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "30px",
                fontSize: "20px",
                fontWeight: "700",
                color: "red",
              }}
            >
              Select A Date Range
            </div>
          ) : null}
          {!selectedLocation ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="datePicker">Start Date</label>
                <input
                  type="date"
                  id="datePicker"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "self-end",
                  width: "30px",
                }}
              >
                -
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="datePicker">End Date</label>
                <input
                  type="date"
                  id="datePicker"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </>
          ) : null}
        </div>
        {!selectedLocation ? (
          <div
            style={{
              border: "1px solid lightgray",
              width: "100%",
              height: "356px",
              marginTop: "20px",
              overflow: "auto",
              position: "relative",
            }}
          >
            <table className="table-generator-history">
              <thead style={{ position: "sticky", top: "0" }}>
                <tr style={{ backgroundColor: "#d2dee7" }}>
                  <th>Location</th>
                  <th>Amount Sold</th>
                  <th>Amount Redeemed</th>
                  <th>Date Range</th>
                </tr>
              </thead>
              <tbody>
                {allLocationsData.map((location, index) => {
                  return (
                    <tr
                      className={
                        location.location !== "Total" ? "table-row" : ""
                      }
                      onClick={() => {
                        if (location.location !== "Total") {
                          console.log(location);
                          setSelectedLocation(location.location);
                        }
                      }}
                      key={index}
                    >
                      <td>{location.location}</td>
                      <td>{formatInDollars(location.amountSold)}</td>
                      <td>{formatInDollars(location.amountRedeemed)}</td>
                      <td>{location.dateRange}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            style={{
              border: "1px solid lightgray",
              width: "100%",
              height: "356px",
              marginTop: "20px",
              overflow: "auto",
              position: "relative",
            }}
          >
            <table className="table-generator-history">
              <thead style={{ position: "sticky", top: "0" }}>
                <tr style={{ backgroundColor: "#d2dee7" }}>
                  <th>Origin Location</th>
                  <th>Amount Redeemed</th>
                </tr>
              </thead>
              <tbody>
                {selectedLocationData.map((location, index) => (
                  <tr key={index}>
                    <td>{location.originLocation}</td>
                    <td>{formatInDollars(location.amountRedeemed)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <button
            onClick={() => {
              if (selectedLocation) {
                setSelectedLocation("");
              } else {
                history.push("/reports");
              }
            }}
            style={{
              width: "200px",
              borderRadius: "4px",
              height: "45px",
              border: "solid 2px #1e3d59",
              backgroundColor: "white",
              fontWeight: "600",
            }}
          >
            BACK
          </button>
          <Button
            onClick={() => {
              if (selectedLocation) {
                csvLinkSelectedLocationRef?.current.link.click();
              } else {
                csvLinkRef?.current.link.click();
              }
            }}
            style={{
              width: "200px",
              height: "44px",
              padding: 0,
              marginLeft: "50px",
            }}
            className="shadow-none reports-button"
          >
            <span style={{ marginRight: "10px", fontSize: "16px" }}>
              EXPORT
            </span>
            <Icons.Download />
          </Button>
          <CSVLink
            ref={csvLinkRef}
            style={{ display: "none" }}
            data={allLocationsData.map((element) => ({
              ...element,
              amountSold: formatInDollars(element.amountSold),
              amountRedeemed: formatInDollars(element.amountRedeemed),
            }))}
            filename={"all-locations-sales-and-redemptions.csv"}
          />
          <CSVLink
            ref={csvLinkSelectedLocationRef}
            style={{ display: "none" }}
            data={selectedLocationData.map((element) => ({
              ...element,
              amountRedeemed: formatInDollars(element.amountRedeemed),
            }))}
            filename={`${selectedLocation}-redemptions.csv`}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default LocationRedemption;
