import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { useAuth } from "../../Contexts/AuthContext";
import axios from "axios";
import { Spinner } from "../../Components";

// const CARD_OPTIONS = {
//   iconStyle: "solid",
//   style: {
//     base: {
//       iconColor: "#c4f0ff",
//       color: "#fff",
//       fontWeight: 500,
//       fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
//       fontSize: "16px",
//       fontSmoothing: "antialiased",
//       ":-webkit-autofill": { color: "#fce883" },
//       "::placeholder": { color: "#87bbfd" },
//     },
//     invalid: {
//       iconColor: "#ffc7ee",
//       color: "#ffc7ee",
//     },
//   },
// };

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const id = useAuth().currentUser.uid;
  const email = useAuth().currentUser.email;

  const [showConfirmPurchase, setShowConfirmPurchase] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentState, setPaymentState] = useState(false);
  let paymentSuccessful = false;

  const redirectOnSuccess = () => {
    paymentSuccessful = true;
    setPaymentState(true);
    setLoading(false);
    handleRedirect();
  };

  const closeModal = () => {
    setShowConfirmPurchase(false);
    setLoading(true);
    setErrorMessage("");
  };

  const handleSubmitSub = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      setShowConfirmPurchase(true);
      //process subscription
      if (props.action === "subscribe") {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/stripe/sub/${id}`,
          {
            payment_method: result.paymentMethod.id,
            email: email,
            price_id: props.price,
          }
        );
        const { client_secret, status } = res.data;

        if(status === "failure") {
          setLoading(false);
          setErrorMessage("Your card was declined.");
          return
        }

        if (status === "requires_action") {
          stripe.confirmCardPayment(client_secret).then(function (result) {
            console.log(result);
            //needs confirmation
            if (result.error) {
              setLoading(false);
              setErrorMessage(result.error.message);
            } else {
              redirectOnSuccess();
            }
          });
        } else {
          redirectOnSuccess();
        }
      }

      //update payment method
      else if (props.action === "updatePayment") {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/stripe/updatePayment/${id}`,
          { payment_method: result.paymentMethod.id }
        );
        if (res.data !== "error") {
          redirectOnSuccess();
        } else {
          setLoading(false);
          setErrorMessage("Your card was declined.");
        }
      }
    }
    paymentSuccessful && handleRedirect();
  };

  const handleRedirect = () => {
    setTimeout(async () => {
      if (props.action === "updatePayment") {
        window.location.reload();
      } else {
        history.push("/profile");
      }
    }, 2000);
  };

  const ConfirmPurchase = () => {
    return (
      <>
        <div className="test-background" />
        {loading ? (
          <div className="test-modal d-flex flex-column align-items-center justify-content-center">
            <h3>Processing...</h3>
            <Spinner />
          </div>
        ) : (
          <div className="test-modal">
            {paymentState ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                {props.action === "subscribe" ? (
                  <h3>Purchase Successful</h3>
                ) : (
                  <h3>Update Successful</h3>
                )}
                <h5> Redirecting...</h5>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h3>Unable to complete processing</h3>
                <p>Error: {errorMessage}</p>
                <button className="profile-btn-modal rounded" onClick={closeModal}>Close</button>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <section className="pricing py-5 d-flex flex-column align-items-center justify-content-center">
      <div className="container">
        <div className="row">
          <form className="border rounded payment-form" onSubmit={handleSubmitSub}>
            <div>{props.tierName}</div>
            <Form.Group>
              <Form.Label className="name-label">Full Name</Form.Label>
              <Form.Control
                type="text"
                name="nameInput"
                className="user-input shadow-none"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="card-number">Card</Form.Label>
              <CardElement />
            </Form.Group>
            <button className="button" type="submit">Pay</button>
          </form>
        </div>
      </div>
      {showConfirmPurchase && ConfirmPurchase()}
    </section>
  );
};

export default PaymentForm;
