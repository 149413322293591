import { useState, useEffect } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import AppContainer from "../../Components/AppContainer/AppContainer";
import axios from "axios";

const formatInDollars = (total) => {
  return (total / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

const localTime = (timestampString) => {
  const timestamp = parseInt(timestampString);
  const date = new Date(timestamp);
  return date.toLocaleString();
};

const UsageLog = () => {
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const { currentUser } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/reports/cards-usage-log/${currentUser.uid}`
        );
        console.log(data.cards);
        setCards(data.cards);
      } catch (err) {
        console.log(`An error occurred: ${err.message}`);
      }
    })();
  }, [currentUser.uid]);

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "1150px",
          margin: "0 auto",
          width: "100%",
          height: "100%",
          paddingTop: "70px",
        }}
      >
        <h2 style={{ width: "100%", margin: "0 0 20px 40px" }}>Usage Log</h2>
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            maxWidth: "400px",
            width: "90%",
            padding: "4px 16px",
            border: "1px solid gray",
            marginBottom: "10px",
          }}
        />
        {cards
          .filter(
            (card) =>
              !!card?.cardNumber?.toLowerCase().includes(search.toLowerCase())
          )
          .map((card) => (
            <div
              className="usage-log"
              style={{
                border: "2px solid #eaeaeb",
                padding: "4px",
                borderRadius: "4px",
                margin: "5px",
                maxWidth: "400px",
                width: "90%",
              }}
              key={card.cardNumber}
            >
              <strong>
                {card.cardNumber.replace(/ARCHIVE.*/, " (archived)")}
              </strong>
              <div
                style={{
                  fontSize: "small",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Initial Balance:{" "}
                <span style={{ color: "green", fontWeight: "600" }}>
                  ${card.initialAmount}
                </span>
                <div />
              </div>
              <div
                style={{
                  fontSize: "small",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Current Balance:
                <span style={{ color: "green", fontWeight: "600" }}>
                  ${card.cardAmount}
                </span>
                <div style={{ width: "13px" }} />
              </div>
              <strong style={{ fontSize: "small" }}>Usage Log</strong>
              {Object.entries(card.usageLog).map(([log, usage]) => (
                <div style={{ fontSize: "small" }} key={log}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        color: "gray",
                        fontSize: "small",
                      }}
                    >
                      {localTime(usage.timestamp)}
                    </div>
                    <div
                      style={{
                        margin: "0 2px",
                        fontWeight: "600",
                        color: "red",
                      }}
                    >
                      {formatInDollars(usage.amount)}
                    </div>
                    <a
                      href={`https://clover.com/r/${usage.orderId}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "#6894BA",
                        textDecoration: "none",
                        fontWeight: "600",
                      }}
                    >
                      RECEIPT
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </AppContainer>
  );
};

export default UsageLog;
