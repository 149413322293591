import React from 'react'
import spinner from '../../Images/spinner.gif';


const Spinner = () => 
    <>
        <img src={spinner} alt="Processing..." style={{width:'200px', margin: 'auto', display:'block'}}/>
    </>

export default Spinner
