import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { MenuState } from "../../Atoms/NavAtoms";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import GiftCardsLogo2 from "../../Images/svgs/GiftCardsLogo2";
import giftCardLogo from "../../Images/gift-card-logo.png";

const SideBar = () => {
  const [menuState, setMenuState] = useRecoilState(MenuState);
  const history = useHistory();
  const { logout } = useAuth();

  useEffect(() => {
    // lock scrolling when instruction modal is open
    menuState
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "initial");
  }, [menuState]);

  const handleLogout = async () => {
    try {
      await logout();
      history.push("/logout");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`${menuState ? "sidebar" : "sidebar sidebar-hide-left"}`}>
        <div>
          <div className="sidebar-logo">
            <img src={giftCardLogo} alt="gift card logo" width={90} />
          </div>
          <div
            style={{
              listStyleType: "none",
              color: "white",
              textAlign: "left",
              fontSize: "20px",
              marginTop: "60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NavLink
              onClick={() => setMenuState(false)}
              className="nav-item"
              activeClassName="nav-item-selected"
              to="/dashboard"
            >
              Dashboard
            </NavLink>
            <NavLink
              onClick={() => setMenuState(false)}
              className="nav-item"
              activeClassName="nav-item-selected"
              to="/number-generator"
            >
              Create Cards
            </NavLink>
            <NavLink
              onClick={() => setMenuState(false)}
              className="nav-item"
              activeClassName="nav-item-selected"
              to="/bulk-upload"
            >
              Import Cards
            </NavLink>
            <NavLink
              onClick={() => setMenuState(false)}
              className="nav-item"
              activeClassName="nav-item-selected"
              to="/reports"
            >
              Reports
            </NavLink>
            <NavLink
              onClick={() => setMenuState(false)}
              className="nav-item"
              activeClassName="nav-item-selected"
              to="/profile"
            >
              My Account
            </NavLink>
            <NavLink
              onClick={() => setMenuState(false)}
              className="nav-item"
              activeClassName="nav-item-selected"
              to="/help"
            >
              Help
            </NavLink>
            <div
              onClick={() => {
                setMenuState(false);
                handleLogout();
              }}
              className="nav-item"
            >
              Logout
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <GiftCardsLogo2 />
        </div>
      </div>
      {menuState ? (
        <div onClick={() => setMenuState(false)} className="sidebar-overlay" />
      ) : null}
    </>
  );
};

export default SideBar;
