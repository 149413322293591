import { useState } from "react";
import AppContainer from "../../Components/AppContainer/AppContainer";
import "./Help.scss";

const Help = () => {
  return (
    <AppContainer>
      <div
        className="help-page-container"
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1150px",
          margin: "0 auto",
          height: "100%",
          paddingTop: "70px",
        }}
      >
        <h2 className="help-title">Help</h2>
        <HelpItem title="How to create cards">
          <ol style={{ marginLeft: "-18px" }}>
            <li>Select the “Create Cards” tab</li>
            <li>
              Enter an optional prefix (e.g. 1234 or ABCD), and the number of
              cards you would like to create. Each sheet of cardstock paper will
              fit 10 cards.
            </li>
            <li>
              Select “Generate Card Numbers” for custom card numbers to be
              created.
            </li>
            <li>
              You may verify the cards here and choose to download a CSV file or
              Print cards.
            </li>
          </ol>
          <strong>Download CSV File</strong>
          <ol type="a">
            <li>
              A excel file will be downloaded to your computer containing custom
              generated card numbers.
            </li>
            <li>
              Take this file to your printer to have numbers added to your gift
              cards.
            </li>
          </ol>
          <strong>Print Cards</strong>
          <ol type="a">
            <li>
              Selecting “Print Cards” will take you to our gift card template to
              create your custom cards.
            </li>
            <li>
              Enter as much information you would like to appear on your cards,
              you are required to enter at least 2 cells.
            </li>
            <li style={{ marginLeft: "-2px" }}>
              Preview your card, and resize your logo, then select “Generate
              PDF”.
            </li>
            <li style={{ marginLeft: "-1px" }}>
              Using the PDF print your gift cards from home or through a local
              printer.
            </li>
          </ol>
        </HelpItem>
        <HelpItem title="How to redeem cards">
          <ol style={{ marginLeft: "-18px" }}>
            <li>
              When the customer is ready to pay and you have pressed the pay
              button. There is a button in the “Other” that is called “Cocoa
              Gift Cards” Press on it.
            </li>
            <li>
              Enter the card number by scanning with the barcode gun or press
              “Scan Card” to use the Clover camera to scan the barcode or QR
              code or type the number in manually.
            </li>
            <li>
              Press the “Redeem” button to redeem the card and go back to the
              register
            </li>
            <li>
              If the order is less than the amount on the card then it will take
              the whole order dollar amount out of the card and there will still
              be a balance on the card. Give the card back to the customer to
              use at a different date.
            </li>
            <li>
              If the order is more than the amount on the card then it will use
              all of the money on the card and add a second payment. You will
              then need to press “Next Payment” and finish the transaction with
              another payment option.
            </li>
          </ol>
        </HelpItem>
        <HelpItem title="How to sell a gift card">
          <ol style={{ marginLeft: "-18px" }}>
            <li>
              You can sell a gift card from the Cocoa Gift Cards app or you can
              search for “Cocoa Gift Cards” in the register or in the category
              Cocoa Gift Cards.
            </li>
            <li>
              When you select it, it will ask you a dollar amount to enter.
            </li>
            <li>
              The register will send you to the Cocoa Gift Cards app and you
              will then be able to choose either a physical card or a digital
              card.
            </li>
          </ol>
          <strong>Physical Cards</strong>
          <ol type="a">
            <li>
              Enter the card number by scanning with the barcode gun or press
              “Scan Card” to use the Clover camera to scan the barcode or QR
              code or
            </li>
            type the number in manually.
            <li>Press “Next” and verify the information.</li>
            <li style={{ marginLeft: "-2px" }}>
              Press “Add to Order” to go back to the register to finish the
              transaction.
            </li>
          </ol>
          <strong>Digital Cards</strong>
          <ol type="a">
            <li>
              The program will automatically create the digital card number.
            </li>
            <li>
              Press “Next” and enter the information of who the card is from and
              to whom it is being sent to.
            </li>
            <li style={{ marginLeft: "-2px" }}>
              Press the “Review” to verify the information
            </li>
            <li style={{ marginLeft: "-1px" }}>
              Press “Add to Order” to go back to the register to finish the
              transaction.
            </li>
          </ol>
        </HelpItem>
        <HelpItem title="How to print new barcodes to place on existing gift cards">
          <ol style={{ marginLeft: "-10px" }}>
            <li>Open cocoagiftcards.com and login</li>
            <li>Click on “Create Cards”</li>
            <li>
              Enter a prefix and card quantity click “Generate Card Numbers”
            </li>
            <li>Download the CSV file</li>
            <li>
              Go to Avery design and print software at
              www.avery.com/software/design-and-print/
            </li>
            <li>“Start Your Design”</li>
            <li>Enter your account or create a new account</li>
            <li>“Sign In”</li>
            <li>Pick a label size and “Select this Template”</li>
            <li>Choose your orientation “Select this Design”</li>
            <li>In the customize tab select More with the three dots</li>
            <li>Select QR and Barcodes</li>
            <li>Click on “Add Barcode or QR Code”</li>
            <li>Select Spreadsheet or Sequential Number”</li>
            <li>Step 1 “Import Data”</li>
            <li>“Browse for File” and select</li>
            <li>Choose the columns with the gift card number and “Next”</li>
            <li>Step 2 Select QRCode or Code 128 or Code 39</li>
            <li>Code type “Text”</li>
            <li>
              Drag and Drop the button with the four arrows to the “Enter
              barcode text”
            </li>
            <li>Select finish</li>
            <li>Adjust the size of the barcode to best fit the label</li>
            <li>(Optional) add an image of your logo or other text.</li>
            <li>“Preview & Print”</li>
            <li>“Get PDF to Print”</li>
            <li>Save or Not to Save. That is the question? Haha</li>
            <li>“Open PDF”</li>
            <li>Print</li>
          </ol>
        </HelpItem>
        <HelpItem title="Printing gift cards through silkcards.com">
          <ol style={{ marginLeft: "-10px" }}>
            <li>Open cocoagiftcards.com and login.</li>
            <li>Click on “Create Cards”.</li>
            <li>
              Enter a prefix and card quantity click “Generate Card Numbers”.
            </li>
            <li>Download the CSV file.</li>
            <li>Go to www.silkcards.com.</li>
            <li>
              Create an account by going to the user right corner and click on
              log in.
            </li>
            <li>Select the New Customer or for existing customers Sign In.</li>
            <li>
              You can select whatever kind of card you would like but we usually
              do the Gift Card option which is a 30mil plastic card. You can
              select them from the upper menu.
            </li>
            <li>Fill out the info on the right.</li>
            <ol type="a">
              <li>Quantity</li>
              <li>Size usually (3.375 x 2.125)</li>
              <li>
                Backside print yes you pick full color or black or grayscale or
                blank
              </li>
              <li>Card Finish</li>
              <li>
                Artwork (if you have artwork already or for $99 they will create
                all of your art files for you… you just need to supply a logo
                and the text you would like and they will put it together) We
                think this is a good value. When you reprint you will not need
                to redo the art work. Silk Cards will keep your files. You will
                just need to supply a new CSV file with new gift card codes.
              </li>
              <li>
                Checkmark barcoding and select either code 128 or QR code.
              </li>
            </ol>
            <li>Add to cart.</li>
            <li>
              Go to shopping cart and upload your art if you have had someone
              other than Silk Cards do the artwork
            </li>
            <li>
              Also add the CSV file from cocoagiftcards.com to the upload art
              area.
            </li>
            <li>Make sure proof required is set to “yes”.</li>
            <li>Use promo code HAPPY10 for 10% off of your first order.</li>
            <li>Checkout.</li>
            <li>
              They will contact you with the proof and you just need to make
              sure the CSV data was received and will be used for the barcoding.
            </li>
            <li>
              Then wait a few weeks for your awesome gift cards to come in the
              mail.
            </li>
          </ol>
        </HelpItem>
        <footer
          style={{
            position: "sticky",
            top: "100vh",
            display: "flex",
            alignItems: "center",
            padding: "30px 0",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <strong>
            Please contact hello@cocoaaccounting.com or (801)-754-5451 with any
            other questions you may have.
          </strong>
        </footer>
      </div>
    </AppContainer>
  );
};

export default Help;

const HelpItem = ({ title, children }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <div style={{ display: "flex", marginTop: "40px" }}>
      <div className="help-checkbox" onClick={() => setHidden(!hidden)}>
        {hidden ? <CheckBoxPlus /> : <CheckboxMinus />}
      </div>
      <div
        className="help-card"
        // todo update height to account for wordwrap that hides text on smaller screens
        style={{
          height: hidden ? "36px" : "",
        }}
      >
        <div className="help-item-title">{title}</div>
        {children}
      </div>
    </div>
  );
};

const CheckBoxPlus = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM14 10H4V8H14V10Z"
        fill="#666666"
        fillOpacity="0.7"
      />
      <line x1="9" y1="4" x2="9" y2="14" stroke="#FFFDFA" strokeWidth="2" />
    </svg>
  );
};

const CheckboxMinus = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM14 10H4V8H14V10Z"
        fill="#666666"
        fillOpacity="0.7"
      />
    </svg>
  );
};
