import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { SaveMoreSvg } from "../../Images/svgs/SaveMoreIcon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import "./_Pricing.scss";

const Pricing = (props) => {
  const [showMonthly, setShowMonthly] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#A68229",
      },
    },
  });

  const selectTier = async (e) => {
    e.preventDefault();

    const prices = [
      "price_1M4VAyFpMcYDokiA0g3JtGwB", // Starter Monthly
      "price_1M4VDBFpMcYDokiAaSnTKBki", // Enhanced Monthly
      "price_1M4VDxFpMcYDokiATvw4G1la", // Pro Monthly
      "price_1M4VFYFpMcYDokiAqburzN4a", // Premier Monthly
      "price_1M4VAyFpMcYDokiAEtv7DXjX", // Starter Yearly
      "price_1M4VDBFpMcYDokiAI62MpWMw", // Enhanced Yearly
      "price_1M4VDxFpMcYDokiAJoTHUhRB", // Pro Yearly
      "price_1M4VFYFpMcYDokiASbQhgApI", // Premier Yearly
    ];
    const tierNames = [
      "Starter Monthly",
      "Enhanced Monthly",
      "Pro Monthly",
      "Premier Monthly",
      "Starter Yearly",
      "Enhanced Yearly",
      "Pro Yearly",
      "Premier Yearly",
    ];
    props.getTier(prices[e.target.lookup_key.value]);
    props.getTierName(tierNames[e.target.lookup_key.value]);
  };

  return (
    <div style={{ width: "100%" }} className="py-5">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "28px",
          marginBottom: "60px",
          marginTop: "20px",
        }}
      >
        <div style={{ fontSize: "22px", fontWeight: "500" }}>Monthly</div>
        <ThemeProvider theme={theme}>
          <Switch
            onChange={() => setShowMonthly((prev) => !prev)}
            checked={!showMonthly}
          />
        </ThemeProvider>
        <div style={{ fontSize: "22px", fontWeight: "500" }}>Yearly</div>
        <div style={{ marginTop: "-60px", marginLeft: "2px" }}>
          <SaveMoreSvg />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {showMonthly ? (
          <>
            <div className="two-card-container">
              <Card
                header="Starter"
                name="250 Cards"
                yearly={false}
                price="9.99"
                topInfo="Save with a yearly subscription of $99.99 through our web app"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="1 Location"
                selectTier={selectTier}
                val={0}
              />
              <Card
                header="Enhanced"
                name="Unlimited Cards"
                yearly={false}
                price="19.99"
                topInfo="Save with a yearly subscription of $199.99 through our web app"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="1 Location"
                selectTier={selectTier}
                val={1}
              />
            </div>
            <div className="two-card-container">
              <Card
                header="Pro"
                name="Unlimited Cards"
                yearly={false}
                price="29.99"
                topInfo="Save with a yearly subscription of $299.99 through our web app"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="2 Locations"
                selectTier={selectTier}
                val={2}
              />
              <Card
                header="Premier"
                name="Unlimited Cards"
                yearly={false}
                price="49.99"
                topInfo="Save with a yearly subscription of $499.99 through our web app"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="Sell gift cards online"
                botInfo4="5 Locations"
                selectTier={selectTier}
                val={3}
              />
            </div>
          </>
        ) : (
          <>
            <div className="two-card-container">
              <Card
                header="Starter"
                name="250 Cards"
                yearly={true}
                price="99.99"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="1 Location"
                selectTier={selectTier}
                val={4}
              />
              <Card
                header="Enhanced"
                name="Unlimited Cards"
                yearly={true}
                price="199.99"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="1 Location"
                selectTier={selectTier}
                val={5}
              />
            </div>
            <div className="two-card-container">
              <Card
                header="Pro"
                name="Unlimited Cards"
                yearly={true}
                price="299.99"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="2 Locations"
                selectTier={selectTier}
                val={6}
              />
              <Card
                header="Premier"
                name="Unlimited Cards"
                yearly={true}
                price="499.99"
                botInfo1="Sell digital and printed gift cards"
                botInfo2="Import previously sold gift cards to the Cocoa system."
                botInfo3="Sell gift cards online"
                botInfo4="5 Locations"
                selectTier={selectTier}
                val={7}
              />
            </div>
          </>
        )}
      </div>
      <div style={{ textAlign: "center", marginTop: "24px" }}>
        Have more locations? Contact us to create a plan for you 801-754-5451.
      </div>
    </div>
  );
};

export default Pricing;

const Card = ({
  header,
  name,
  price,
  yearly,
  topInfo,
  botInfo1,
  botInfo2,
  botInfo3,
  botInfo4,
  selectTier,
  val,
}) => {
  return (
    <div
      style={{
        width: "228px",
        height: "456px",
        border: "2px solid black",
        borderRadius: "12px",
        padding: "0px",
        marginBottom: "24px",
      }}
    >
      <div className="pricing-card-top">
        <div
          style={{
            fontSize: "30px",
            marginTop: "-26px",
            backgroundColor: "white",
            padding: "0 4px",
          }}
        >
          {header}
        </div>
        <div style={{ fontSize: "22px", marginTop: "16px" }}>{name}</div>
        <div style={{ fontSize: "38px", marginTop: "20px" }}>
          {`$${price}`}
          <span style={{ fontSize: "14px", marginLeft: "4px" }}>
            {yearly ? "/ Year" : "/ Month"}
          </span>
        </div>
        <div
          style={{
            fontSize: "12px",
            textAlign: "center",
            padding: "0 5px",
            marginTop: `${yearly ? "20px" : "7px"}`,
          }}
        >
          {topInfo}
        </div>
      </div>
      <div className="pricing-card-btn-container">
        <form onSubmit={selectTier}>
          <input type="hidden" name="lookup_key" value={val} />
          <Button
            id="checkout-and-portal-button"
            type="submit"
            className="pricing-card-button shadow-none"
          >
            SELECT
          </Button>
        </form>
      </div>
      <div className="pricing-card-bottom">
        <div className="pricing-card-bot-info">{botInfo1}</div>
        <div className="pricing-card-bot-info">{botInfo2}</div>
        <div className="pricing-card-bot-info">{botInfo3}</div>
        <div className="pricing-card-bot-info">{botInfo4}</div>
      </div>
    </div>
  );
};
