const CloudIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3334 25.3333L19.0001 19L12.6667 25.3333"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 19V33.25"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2842 29.1175C33.8285 28.2756 35.0485 26.9434 35.7515 25.3312C36.4546 23.7189 36.6007 21.9185 36.1669 20.2139C35.733 18.5094 34.7439 16.9979 33.3556 15.918C31.9673 14.838 30.2589 14.2512 28.5001 14.25H26.5051C26.0258 12.3963 25.1326 10.6754 23.8925 9.21659C22.6524 7.7578 21.0977 6.59911 19.3454 5.82764C17.593 5.05618 15.6886 4.692 13.7752 4.7625C11.8619 4.83299 9.98941 5.33632 8.29859 6.23465C6.60776 7.13298 5.14258 8.40293 4.01321 9.94902C2.88384 11.4951 2.11965 13.2771 1.77811 15.1611C1.43657 17.045 1.52656 18.9819 2.04131 20.826C2.55607 22.6702 3.48219 24.3736 4.75006 25.8083"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3334 25.3333L19.0001 19L12.6667 25.3333"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudIcon;
