import { useState, useEffect, useRef } from "react";
import {
  Image,
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import CloudIcon from "../../Images/svgs/CloudIcon";
import PlusIcon from "../../Images/svgs/PlusIcon";
import MinusIcon from "../../Images/svgs/MinusIcon";
import axios from "axios";
import { useAuth } from "../../Contexts/AuthContext";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { storage } from "../../Utils/firebase";
import { Spinner } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { CardPrinterState } from "../../Atoms/CardPrinterAtoms";
import AppContainer from "../../Components/AppContainer/AppContainer";

const CardPrinter = () => {
  const [cardPrinterState] =
    useRecoilState(CardPrinterState);

  // const [data, setData] = useState([
  //   { Number: "3870657402" },
  //   { Number: "3872344402" },
  //   { Number: "3855557402" },
  // ]);
  // input states
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [steapt, setSteapt] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [terms, setTerms] = useState("");

  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [logoSize, setLogoSize] = useState(50);

  const [isLogoEnabled, setIsLogoEnabled] = useState(false);

  const [hasRequiredFields, setHasRequiredFields] = useState(false);
  const [imageUpload, setImageUpload] = useState("");
  const [blobImageUrl, setBlobImageUrl] = useState("");

  const [dragActive, setDragActive] = useState(false);

  const { currentUser } = useAuth();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BACKEND_API}/account/card-printer/${currentUser.uid}`
        );

        const fields = response.data;

        if (fields.companyName) {
          setCompanyName(fields?.companyName);
        }
        if (fields.address) {
          setAddress(fields.address);
        }
        if (fields.steapt) {
          setSteapt(fields.steapt);
        }
        if (fields.city) {
          setCity(fields.city);
        }
        if (fields.state) {
          setState(fields.state);
        }
        if (fields.zip) {
          setZip(fields.zip);
        }
        if (fields.website) {
          setWebsite(fields.website);
        }
        if (fields.phone) {
          setPhone(fields.phone);
        }
        if (fields.terms) {
          setTerms(fields.terms);
        }
        if (fields.logoSize) {
          setLogoSize(fields.logoSize);
        }
        if (fields.logoUrl) {
          setIsLogoEnabled(true);
          setImageUpload(fields.logoUrl);
          setBlobImageUrl(fields.logoUrl);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentUser.uid]);

  // Generate PDF button requires at least 2 input fields to be enabled and contain a value to be enabled
  // hasRequiredFields checks this
  useEffect(() => {
    // any state change that changes pdf sets pdfIsLoading to false
    // this prevents the pdf from constantly updating every time we make a change (very laggy when this happens)
    // this allows user to make changes and only update pdf after clicking generate pdf button
    setPdfIsLoading(false);

    let completedFields = 0;

    if (companyName !== "") {
      completedFields += 1;
    }
    if (address !== "") {
      completedFields += 1;
    }
    if (website !== "") {
      completedFields += 1;
    }
    if (phone !== "") {
      completedFields += 1;
    }
    if (terms !== "") {
      completedFields += 1;
    }
    if (isLogoEnabled === true && imageUpload !== "") {
      completedFields += 1;
    }

    if (completedFields >= 2) {
      setHasRequiredFields(true);
    } else {
      setHasRequiredFields(false);
    }
  }, [
    companyName,
    address,
    website,
    phone,
    terms,
    isLogoEnabled,
    imageUpload,
    // these are for setting PDFisLoading to false
    logoSize,
    steapt,
    city,
    state,
    zip,
    dragActive,
  ]);

  // https://github.com/diegomura/react-pdf/issues/680
  // curl google font url to get .ttf file
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf",
        fontWeight: 700,
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf",
        fontWeight: 300, // light 300
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      padding: 20,
      lineHeight: 1.3,
    },
    section: {
      height: "20%",
      width: "50%",
      backgroundColor: "white",
      border: "0.5px solid black",
    },
    logo: {
      marginBottom: 2,
      width: `${logoSize}%`,
      objectFit: "contain",
    },

    top: {
      height: "75%",
      display: "flex",
      flexDirection: "row",
    },
    bottom: {
      height: "25%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    left: {
      height: "100%",
      width: "32%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    verticalDivider: {
      height: "47%",
      borderRight: "1px solid #b8b7b5",
    },
    right: {
      paddingTop: "6px",
      height: "100%",
      width: "68%",
      overflow: "hidden",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontSize: 11,
    },
    amount: {
      fontSize: 11,
      fontFamily: "Roboto",
      fontWeight: "300",
    },
    code: {
      fontSize: 7,
      fontFamily: "Roboto",
      fontWeight: "300",
    },
    qrCode: {
      width: "50%",
    },
    companyName: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    address: {
      fontSize: 8,
      fontFamily: "Roboto",
      fontWeight: "300",
    },
    phone: {
      fontSize: 8,
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    website: {
      fontSize: 9,
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    terms: {
      width: "98%",
      height: "100%",
      padding: 3,
      wordWrap: "break-word",
      overflowWrap: "break-word",
      fontSize: 6,
      fontFamily: "Roboto",
      fontWeight: "300",
    },
    termsOfUse: {
      fontSize: 7,
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
  });

  const CardsPDF = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {cardPrinterState.map((card, index) => (
            <View key={index} style={styles.section}>
              <View style={styles.top}>
                <View style={styles.left}>
                  <Text style={styles.amount}>Gift Card $___</Text>
                  <Image
                    style={styles.qrCode}
                    src={`https://api.qrserver.com/v1/create-qr-code/?data=${card.cardNumber}`}
                  />
                  <Text style={styles.code}>{card.cardNumber}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    justifyContent: "center",
                  }}
                >
                  <View style={styles.verticalDivider} />
                </View>
                <View style={styles.right}>
                  {isLogoEnabled && imageUpload !== "" ? (
                    <Image style={styles.logo} src={blobImageUrl} />
                  ) : null}
                  {companyName !== "" ? (
                    <Text style={styles.companyName}>{companyName}</Text>
                  ) : null}
                  {phone !== "" ? (
                    <Text style={styles.phone}>{phone}</Text>
                  ) : null}

                  {address.length > 0 ? (
                    <Text style={styles.address}>{address}</Text>
                  ) : null}
                  {steapt.length > 0 ? (
                    <Text style={styles.address}>{steapt}</Text>
                  ) : null}
                  {city.length > 0 || state.length > 0 || zip.length > 0 ? (
                    <Text style={styles.address}>{`${city}${
                      city.length > 0 ? "," : ""
                    } ${state} ${zip}`}</Text>
                  ) : null}
                  {website !== "" ? (
                    <Text style={styles.website}>{website}</Text>
                  ) : null}
                </View>
              </View>
              <View style={styles.bottom}>
                <Text style={styles.terms}>
                  {terms.length > 0 ? (
                    <>
                      <Text style={styles.termsOfUse}>TERMS OF USE: </Text>
                      {terms}
                    </>
                  ) : null}
                </Text>
              </View>
            </View>
          ))}
        </Page>
      </Document>
    );
  };

  const DisplayCard = () => {
    return (
      <div
        style={{
          height: "260px",
          width: "430px",
          background: "white",
          border: "solid 0.5px gray",
          overflow: "hidden",
        }}
      >
        <div className="card-top">
          <div className="qr-code">
            <div>Gift Card $___</div>
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?data=1212121212`}
              alt=""
              style={{
                width: "50%",
              }}
            />
            <div style={{ fontSize: "13px" }}>1212121212121212</div>
          </div>
          {/* vertical divider */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRight: "solid 1px #b8b7b5",
                height: "50%",
                marginTop: "12px",
              }}
            ></div>
          </div>
          <div className="business-details">
            <div>
              {isLogoEnabled && imageUpload !== "" ? (
                <img
                  src={blobImageUrl}
                  alt="logo"
                  style={{ width: `${logoSize}%`, marginTop: "10px" }}
                />
              ) : null}
            </div>
            <div className="company-name">{companyName}</div>
            <div className="phone">
              <strong>{phone}</strong>
            </div>
            <div className="address">
              <div>{address}</div>
              <div>{steapt}</div>
              <div>
                {`${city}${city.length > 0 ? "," : ""} ${state} ${zip}`}
              </div>
            </div>
            <div className="website">
              <strong>{website}</strong>
            </div>
          </div>
        </div>
        <div className="terms-of-use">
          {terms.length > 0 ? (
            <>
              <strong>TERMS OF USE: </strong>
              {/* allow multiple lines in preview card */}
              {terms.split("").map((letter, index) => {
                if (letter === "\n") {
                  return <br key={index}></br>;
                } else {
                  return letter;
                }
              })}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const generatePDF = async () => {
    setPdfIsLoading(true);

    try {
      // Todo - firebase photo upload
      // upload photo to firebase & get photoUrl

      // const imageRef = ref(storage, `images/${currentUser.uid}/logo`);
      // await uploadBytes(imageRef, imageUpload).then(() => {});

      // const logoUrl = await getDownloadURL(imageRef);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/account/card-printer/${currentUser.uid}`,
        {
          // logoUrl,
          companyName,
          address,
          steapt,
          city,
          state,
          zip,
          website,
          phone,
          terms,
          logoSize,
        }
      );
      console.log(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // only allow jpg or png
      if (/\.(jpg|png)$/.test(e.dataTransfer.files[0].name)) {
        setImageUpload(e.dataTransfer.files[0]);
        setBlobImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
    }
  };

  return (
    <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "1053px",
            margin: "0 auto",
          }}
        >
          <h1 style={{ margin: "10px 0px 0px 40px" }}>Create Cards</h1>
          <div
            style={{ textAlign: "center", margin: "30px", fontSize: "16px" }}
          >
            Enter any information you would like to appear on your gift cards.
            You must enter at least two fields. Though gift cards can be
            purchased and redeemed at multiple locations, only one address can
            be displayed. You can preview details and resize your logo below.
          </div>
        </div>

        <div className="cardprinter-container">
          <div className="left-right-container">
            {/* left side */}
            <div className="left">
              <div>
                <input
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  style={{
                    width: "400px",
                    marginLeft: "20px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  placeholder="Company Name"
                />
              </div>
              <div>
                <input
                  style={{
                    width: "400px",
                    marginLeft: "20px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  placeholder="Address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                />
              </div>
              <div style={{ marginLeft: "18px" }}>
                <input
                  style={{
                    width: "192px",
                    marginLeft: "0px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  placeholder="STE/APT"
                  onChange={(e) => setSteapt(e.target.value)}
                  value={steapt}
                />
                <input
                  style={{
                    width: "192px",
                    marginLeft: "15px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  placeholder="City"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </div>
              <div style={{ marginLeft: "18px" }}>
                <input
                  style={{
                    width: "192px",
                    marginLeft: "0px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  placeholder="State"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
                <input
                  style={{
                    width: "192px",
                    marginLeft: "15px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  placeholder="Zip Code"
                  onChange={(e) => setZip(e.target.value)}
                  value={zip}
                />
              </div>
              <div>
                <input
                  placeholder="Website"
                  style={{
                    width: "400px",
                    marginLeft: "20px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                />
              </div>
              <div>
                <input
                  placeholder="Phone"
                  style={{
                    width: "400px",
                    marginLeft: "20px",
                    marginBottom: "12px",
                    padding: "8px",
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div
                style={{
                  width: "433px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <textarea
                  placeholder="Terms of Use"
                  style={{
                    width: "400px",
                    marginLeft: "26px",
                    padding: "8px",
                  }}
                  cols="30"
                  rows="5"
                  onChange={(e) => setTerms(e.target.value)}
                  value={terms}
                />
              </div>
              <p
                style={{
                  width: "400px",
                  marginLeft: "50px",
                  marginBottom: "0px",
                  marginTop: "5px",
                  fontSize: "small",
                  color: "#a6a5a4",
                }}
              >
                Limit 370 characters
              </p>
              <div style={{ display: "flex",marginLeft: "32px" }}>
                <input
                  type="checkbox"
                  checked={isLogoEnabled}
                  onChange={() => setIsLogoEnabled((prev) => !prev)}
                />
                <div
                  style={{
                    width: "400px",
                    marginLeft: "4x",
                    padding: "8px",
                    fontSize: "15px",
                  }}
                >
                  Include Company Logo
                </div>
              </div>
              <div
                style={{
                  width: "400px",
                  height: "60px",
                  border: "1px dashed #79747E",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginLeft: "16px",
                  backgroundColor: `${dragActive ? "#CFE6E7" : ""}`,
                }}
              >
                <CloudIcon />
                <div>
                  <div style={{ fontSize: "13px", marginBottom: "4px" }}>
                    Select a file or drag and drop here
                  </div>
                  <div style={{ fontSize: "12px", color: "#c5c5c5" }}>
                    JPG or PNG, 46x78 px for best results.
                  </div>
                </div>
                <button
                  style={{
                    fontSize: "10px",
                    width: "83px",
                    height: "23px",
                    backgroundColor: "white",
                    color: "#6894ba",
                    border: "solid 1px #6894ba",
                    borderRadius: "5px",
                  }}
                  onClick={() => hiddenFileInput.current.click()}
                >
                  SELECT FILE
                </button>
                <input
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      setImageUpload(e.target.files[0]);
                      setBlobImageUrl(URL.createObjectURL(e.target.files[0]));
                    }
                  }}
                />
              </div>
            </div>
            {/* vertical divider */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  borderRight: "solid 4px #daeabe",
                  height: "92%",
                }}
              ></div>
            </div>
            {/* right side */}
            <div className="right">
              <div className="preview-text">Preview</div>
              {DisplayCard()}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  width: "320px",
                  justifyContent: "space-between",
                }}
                className="adjust-controls"
              >
                <div>Adjust logo size</div>
                <div className="controls">
                  <div
                    style={{
                      cursor: "pointer",
                      width: "22px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setLogoSize((prev) => prev - 5)}
                  >
                    <MinusIcon />
                  </div>
                  <div>{logoSize}</div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setLogoSize((prev) => prev + 5)}
                  >
                    <PlusIcon />
                  </div>
                </div>
              </div>
              <button
                disabled={!hasRequiredFields}
                onClick={generatePDF}
                className={
                  hasRequiredFields ? "gen-pdf-btn" : "gen-pdf-btn disabled"
                }
              >
                {pdfIsLoading ? (
                  <PDFDownloadLink
                    document={<CardsPDF />}
                    fileName="giftcards.pdf"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {({ blob, url, loading, error }) => {
                      if (loading === true) {
                        return (
                          <Spinner
                            animation="border"
                            style={{ width: "24px", height: "24px" }}
                          />
                        );
                      } else if (url !== null) {
                        console.log(url);
                        return (
                          <div
                            style={{
                              backgroundColor: "#253C57",
                              borderRadius: "4px",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              textDecoration: "none",
                            }}
                          >
                            DOWNLOAD
                          </div>
                        );
                      }
                    }}
                  </PDFDownloadLink>
                ) : (
                  "GENERATE PDF"
                )}
              </button>
              <div className="logo-container">
                {/* //todo maybe adjust this space */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default CardPrinter;
