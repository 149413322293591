import SideBar from "../SideBar/SideBar";
import TopBar from "../TopBar/TopBar";
import "./AppContainer.scss";

const AppContainer = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: "#36546e",
        minHeight: "100vh",
        display: "flex",
        flexGrow: 1,
        overflowX: "hidden",
      }}
    >
      <TopBar />
      <SideBar />
      <div className="app-container">{children}</div>
    </div>
  );
};

export default AppContainer;
