import React, { useState, useEffect } from "react";
import axios from "axios";
import { FilePicker } from "react-file-picker";
import * as XLSX from "xlsx";
import { useAuth } from "../../Contexts/AuthContext";
import { Button } from "react-bootstrap";
import Icons from "../../Images/Icons";
import useWindowDimensions from "../../CustomHooks/useWindowDimensions";
import Loading from "../../Components/Loading/Loading";
import AppContainer from "../../Components/AppContainer/AppContainer";
import { VerticalLinearGradientBar } from "../../Components";

// bulk import cards tool
const ImportCards = () => {
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertColor, setAlertColor] = useState("danger");
  const [cardsAlreadyInDatabase, setCardsAlreadyInDatabase] = useState("");
  const [showInstructions, setShowInstructions] = useState(false);
  const [fileName, setFileName] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [data, setData] = useState([]);
  const [userSelectedFile, setUserSelectedFile] = useState({});

  const selectedFile = [];
  const matches = [];
  const { currentUser } = useAuth();

  // get height and width of screen in pixels
  const { width } = useWindowDimensions();

  // lock scrolling when instruction modal is open
  useEffect(() => {
    showInstructions
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "initial");
  });

  const InstructionsModal = () => {
    return (
      <div
        className="test-background"
        onClick={() => {
          setShowInstructions(false);
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="reset-password-modal"
          style={{ height: "500px", maxWidth: "632px" }}
        >
          <VerticalLinearGradientBar height="100%" width="50px" />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div>
              <div
                onClick={() => setShowInstructions(false)}
                style={{ textAlign: "right", cursor: "pointer" }}
              >
                <CloseBtn />
              </div>
              <div
                style={{
                  marginBottom: "20px",
                }}
                className="reset-modal-text"
              >
                Import Guidelines
              </div>
            </div>
            <div className="reset-modal-text">
              Each cell containing data must be listed under one of the
              following
            </div>
            <div className="reset-modal-text">
              Required Columns:
              <ul>
                <li>Number (e.g. 1234567890</li>
                <li>Amount (e.g. 20.00)</li>
              </ul>
            </div>
            <div className="reset-modal-text">
              Optional Columns
              <ul>
                <li>ActivatedDates (mm/dd/yyyy)</li>
                <li>CardType (Purchase or Return)</li>
              </ul>
            </div>
            <div
              style={{
                marginBottom: "20px",
              }}
              className="reset-modal-text"
            >
              Our importer is sensitive, make sure words appear exactly as they
              are in these guidelines.
            </div>
          </div>
        </div>
      </div>
    );
  };

  // check for invalid dates
  const checkValidDate = (date) => {
    const d = date.toString().replaceAll(" ", "/");
    const result = new Date(d);
    return new Promise((resolve) => {
      if (d.length > 0 && result.toString() === "Invalid Date")
        resolve("invalid");
      else if (result.getTime() < 0 || result.getTime() > new Date())
        resolve("out of range");
      else resolve("valid");
    });
  };

  // format dates to display
  const formatDate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = date.toString().replaceAll(" ", "/");
    const result = new Date(d);

    if (result.toString() === "Invalid Date") {
      return "Invalid Date";
    } else {
      return `${parseInt(date.substring(3, 5))} ${
        monthNames[parseInt(date.substring(0, 2)) - 1]
      } ${date.substring(6, 10)}`;
    }
  };

  // displays cards from the imported csv file as long as the uploaded file is valid
  const displayCards = () => {
    return width < 900
      ? data.map((card, index) => {
          return (
            <div
              style={{
                border: "2px solid #eae8e7",
                borderRadius: "10px",
                marginBottom: "10px",
                width: "90%",
                padding: "10px",
              }}
              // className="imported-card"
              className="import-cards-cards"
              key={index}
            >
              <div className="card-data">
                <div
                  style={textColor(card.Number) ? { color: "red" } : {}}
                >{`Card Number: ${card.Number.toUpperCase()}`}</div>
                {card.Amount && (
                  <div>{`Amount: $${parseFloat(card.Amount).toFixed(2)}`}</div>
                )}
                {card.Amount && card.ActivatedDate && (
                  <div>{`Activated Date: ${formatDate(
                    card.ActivatedDate
                  )}`}</div>
                )}
                {card.Amount && card.CardType && (
                  <div>{`Type: ${card.CardType[0].toUpperCase()}${card.CardType.slice(
                    1
                  )}`}</div>
                )}
                {card.Amount && card.Category && (
                  <div>{`Category: ${card.Category[0].toUpperCase()}${card.Category.slice(
                    1
                  )}`}</div>
                )}
              </div>
            </div>
          );
        })
      : data.length > 0 && (
          <div
            style={{
              border: "1px solid lightgray",
              width: "100%",
              height: "356px",
              overflow: "auto",
              position: "relative",
            }}
          >
            <table className="table-generator-history">
              <thead style={{ position: "sticky", top: "0" }}>
                <tr style={{ backgroundColor: "#d2dee7" }}>
                  <th>Card Number</th>
                  <th>Amount</th>
                  <th>Activated Date</th>
                  <th>Card Type</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                {data.map((card, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={textColor(card.Number) ? { color: "red" } : {}}
                      >
                        {card.Number.toUpperCase()}
                      </td>
                      {card.Amount ? (
                        <td>{`$${parseFloat(card.Amount).toFixed(2)}`}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {card.ActivatedDate && card.Amount ? (
                        <td>{formatDate(card.ActivatedDate)}</td>
                      ) : (
                        <td>-</td>
                      )}
                      {card.ActivatedDate && card.CardType ? (
                        <td>
                          {`${card.CardType[0].toUpperCase()}${card.CardType.slice(
                            1
                          )}`}
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                      {card.ActivatedDate && card.Category ? (
                        <td>
                          {`${card.Category[0].toUpperCase()}${card.Category.slice(
                            1
                          )}`}
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
  };

  // changes card number font color to red if that number is already in the database
  const textColor = (number) => {
    const newAlert = cardsAlreadyInDatabase.replaceAll(",", " ").split(" ");
    let result = false;

    // change font color of card number to red if alert contains that card number
    newAlert.map((word) => {
      if (word == number) result = true;
    });

    // changes color to red if card number is missing
    if (number.length === 0) result = true;

    return result;
  };

  // check for any invalid special characters in any data cell in file
  const checkSpecialChars = async (cell) => {
    return new Promise((resolve) => {
      // regex pattern that checks for all special characters except decimal point (.) and forward slash (/)
      if (cell && cell.length > 0) var pattern = new RegExp(/[^A-Za-z0-9]/);
      else var pattern = new RegExp(/[^A-Za-z0-9]/);

      // indicate an invalid file if data cell contains invalid special characters
      resolve(pattern.test(cell));
    });
  };

  // check for any duplicate column headers and return the count of each header
  const checkDuplicateHeaders = (headers, thisHeader) => {
    return new Promise((resolve) => {
      let headerCount = 0;
      headers.forEach((header) => {
        if (header === thisHeader) headerCount += 1;
      });
      headerCount === 2 ? resolve(thisHeader) : resolve(headerCount);
    });
  };

  // called when file is uploaded
  const handleFile = (file) => {
    setLoading(true);
    setInvalidFile(false);
    setFileName(file.name);
    selectedFile.push(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryString = e.target.result;
      const workbook = XLSX.read(binaryString, {
        raw: true,
        type: "binary",
        cellText: false,
        cellDates: true,
      });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_csv(worksheet, {
        header: 1,
        raw: true,
        rawNumbers: true,
        dateNF: "mm dd yyyy",
      });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  // called by handleFile()
  // checks for errors in the file and creates the list to display in the table if all data cells are valid
  const processData = async (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const list = [];
    const invalidDates = [];
    const outOfRangeDates = [];
    const cardNumbers = [];
    const duplicateNumbers = [];
    const duplicateHeaders = [];
    const invalidHeaders = [];
    const emptyHeaders = [];
    const illegalValues = [];
    const invalidCardTypes = [];
    const invalidCategories = [];
    const invalidAmounts = [];
    const invalidSpecialChars = [];
    let NumberHeaderIndex;
    let AmountHeaderIndex;
    let ActivatedDateIndex;
    let CardTypeIndex;
    let CategoryIndex;

    // store the sum of any duplicate headers and store the index of each header
    for (let header of headers) {
      // check if any of the headers are not one of the 5 allowed header names: Number, Amount, ActivatedDate, CardType, and Category
      switch (header) {
        case "Number":
          NumberHeaderIndex = headers.indexOf(header);
          break;
        case "Amount":
          AmountHeaderIndex = headers.indexOf(header);
          break;
        case "ActivatedDate":
          ActivatedDateIndex = headers.indexOf(header);
          break;
        case "CardType":
          CardTypeIndex = headers.indexOf(header);
          break;
        case "Category":
          CategoryIndex = headers.indexOf(header);
          break;
        case "":
          break;
        default:
          // if a header name is not one of the valid options above, push that header name onto the invalidHeaders array
          invalidHeaders.push(header);
          break;
      }

      // make sure the headers do not contain any duplicates
      if (header.length > 0) {
        const response = await checkDuplicateHeaders(headers, header);
        response === header &&
          !duplicateHeaders.includes(header) &&
          duplicateHeaders.push(header);
      }
    }

    // begin parsing through each row of data in the file
    for (let i = 1; i < dataStringLines.length - 1; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );

      // always capitalize card number
      row[NumberHeaderIndex] = row[NumberHeaderIndex]?.toUpperCase();

      // if a card has a card type that is not "Physical" or "Digital", push that row number onto invalidCardTypes array
      row[CardTypeIndex] &&
        row[CardTypeIndex] !== "Physical" &&
        row[CardTypeIndex] !== "Digital" &&
        invalidCardTypes.push(i + 1);

      // if a card has a category that is not "Purchase" or "Return", push that row number onto invalidCategories array
      row[CategoryIndex] &&
        row[CategoryIndex] !== "Purchase" &&
        row[CategoryIndex] !== "Return" &&
        invalidCategories.push(i + 1);

      // check if any row of data contains an activated date, card type, and/or category but no card amount
      if (
        AmountHeaderIndex &&
        row[AmountHeaderIndex].length <= 0 &&
        (row[CardTypeIndex] || row[ActivatedDateIndex] || row[CategoryIndex])
      ) {
        // push the row number onto the illegalValue array if illegal values exist
        illegalValues.push(i + 1);
      }

      // push each duplicate card number onto the duplicateNumbers array
      if (
        cardNumbers.includes(row[NumberHeaderIndex]) &&
        row[NumberHeaderIndex] !== "" &&
        !duplicateNumbers.includes(row[NumberHeaderIndex])
      ) {
        duplicateNumbers.push(row[NumberHeaderIndex]);
      }

      // push each card number onto the cardNumbers array
      cardNumbers.push(row[NumberHeaderIndex]);

      // check for any data cells that are not listed under a valid column header
      for (let header of headers) {
        header.length === 0 &&
          row[headers.indexOf(header)].length > 0 &&
          emptyHeaders.push(headers.indexOf(header));
      }

      // check for correct date format between Jan 1 1970 and current date
      if (ActivatedDateIndex) {
        const response = await checkValidDate(row[ActivatedDateIndex]);
        response === "out of range" && outOfRangeDates.push(i + 1);
        response === "invalid" && invalidDates.push(i + 1);
      }

      // check for correct amount format in each row
      if (AmountHeaderIndex) {
        let decimals = 0;

        // regex expression that checks for letters in the stringified "amounts" variable below
        const pattern = new RegExp(/[^0-9\.]+/g);
        const amounts = [];

        // loop through each data cell in the row and sum up how many decimal points and spaces the data cell contains
        for (let char = 0; char < row[AmountHeaderIndex].length; char++) {
          if (
            row[AmountHeaderIndex][char] == "." ||
            row[AmountHeaderIndex][char] == " "
          ) {
            decimals += 1;
          }
          // decimals > 1 && invalidAmounts.push(i + 1) <-- fix and add back in later
          amounts.push(row[AmountHeaderIndex][char]);
        }
        const joined = amounts.join("");

        // check if card amount is not in correct number format
        pattern.test(joined) && invalidAmounts.push(i + 1);

        // if the row's Amount data cell is empty, remove that data cell from the amounts variable
        parseInt(joined) === 0 && amounts.splice(joined, 1);
      }

      // check for any rows that contain data but no card number
      if (
        NumberHeaderIndex !== undefined &&
        row.join("").length !== 0 &&
        row[NumberHeaderIndex].length === 0
      ) {
        setAlertColor("danger");
        setAlert("It looks like your file is missing one or more card numbers");
        setInvalidFile(true);
      }

      // push card objects onto list if the file doesn't contain any missing headers
      if (emptyHeaders.length === 0) {
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          let d = row[j];

          if (d === 0) row[j].splice(d, 1);

          // remove potential quotes around any of the values
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.subtring(d.length - 1, 1);
          }

          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // set the objects Amount value as an empty string if an amount isn't present in that row of the file
        if (parseInt(obj.Amount) === 0) obj.Amount = "";

        // push each card object onto list array
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }

      // check for any data cell exists in the file in a column with no header
      else {
        data.length = 0;
        setInvalidFile(true);
        setAlertColor("danger");
        setAlert(
          "Oops! Your file has one or more data cells in a column with no header. Please correct and re-upload your file."
        );
        return setLoading(false);
      }
    }

    // check for any invalid special characters in any of the card numbers
    for (let number of cardNumbers) {
      const response = await checkSpecialChars(number);
      if (response === true)
        invalidSpecialChars.push(cardNumbers.indexOf(number) + 2);
    }

    // check for any invalid column headers
    if (invalidHeaders.length > 0) {
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `Oops! Your file contains the following invalid 
                column header${invalidHeaders.length > 1 ? `s:` : `:`} 
                ${invalidHeaders.slice(0, -1).join(",").replaceAll(",", ", ")}
                ${invalidHeaders.length > 1 ? `and` : ``} 
                ${invalidHeaders.slice(-1)}.
                Please correct and re-upload your file.`
      );
      setInvalidFile(true);
      return setLoading(false);
    }

    // check for any duplicate column headers
    if (duplicateHeaders.length > 0) {
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `The 
                ${duplicateHeaders
                  .slice(0, -1)
                  .join(",")
                  .replaceAll(",", ", ")} 
                ${duplicateHeaders.length > 1 ? `and` : ``} 
                ${duplicateHeaders.slice(-1)}
                column header${duplicateHeaders.length > 1 ? `s` : ``} 
                can't be listed in your file more than once.
                Please correct and re-upload your file.`
      );
      setInvalidFile(true);
      return setLoading(false);
    }

    // check for invalid date format in ActivatedDate column
    if (invalidDates.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `Invalid date format in row${invalidDates.length > 1 ? `s` : ``} 
                ${invalidDates.slice(0, -1).join(",").replaceAll(",", ", ")} 
                ${invalidDates.length > 1 ? `and` : ``} 
                ${invalidDates.slice(-1)} of your ActivatedDate column. 
                Please correct and re-upload your file.`
      );
      return setLoading(false);
    }

    // check for any rows in the file that contain invalid special characters
    if (invalidSpecialChars.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `Invalid special characters in row${
          invalidSpecialChars.length > 1 ? `s` : ``
        } 
          ${invalidSpecialChars.slice(0, -1).join(",").replaceAll(",", ", ")} 
          ${invalidSpecialChars.length > 1 ? `and` : ``} 
          ${invalidSpecialChars.slice(-1)} of your file. 
          Please remove these and re-upload your file.`
      );
      return setLoading(false);
    }

    // check for the required Number column
    if (!headers.includes("Number")) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert('Oops! Your file is missing the required "Number" column');
      return setLoading(false);
    }

    // check for the required Amount column
    if (!headers.includes("Amount")) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert('Oops! Your file is missing the required "Amount" column');
      return setLoading(false);
    }

    // check for cards that do not have an amount, but do have an ActivatedDate, CardType, and/or Category
    if (illegalValues.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `Cards without an Amount cannot have an ActivatedDate, CardType, or Category.
        Please remove these values from the cards in row${
          illegalValues.length > 1 ? `s` : ``
        } 
        ${illegalValues.slice(0, -1).join(",").replaceAll(",", ", ")} 
        ${illegalValues.length > 1 ? `and` : ``} 
        ${illegalValues.slice(-1)} of your file and re-upload.`
      );
      return setLoading(false);
    }

    // check for any rows that have an invalid amount format
    if (invalidAmounts.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `Invalid amount format in row${invalidAmounts.length > 1 ? `s` : ``} 
        ${invalidAmounts.slice(0, -1).join(",").replaceAll(",", ", ")} 
        ${invalidAmounts.length > 1 ? `and` : ``} 
        ${invalidAmounts.slice(-1)} of your file. 
        Please correct and re-upload your file.`
      );
      return setLoading(false);
    }

    // check for any rows that have an ActivatedDate before January 1 1970 or after the current date
    if (outOfRangeDates.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `Invalid date in row${outOfRangeDates.length > 1 ? `s` : ``} 
        ${outOfRangeDates.slice(0, -1).join(",").replaceAll(",", ", ")} 
        ${outOfRangeDates.length > 1 ? `and` : ``} 
        ${outOfRangeDates.slice(-1)} of your ActivatedDate column. 
        Each date must be between January 1, 1970 and today.`
      );
      return setLoading(false);
    }

    // check for any rows of the file that contain an invalid card type
    if (invalidCardTypes.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `The CardType data in row${invalidCardTypes.length > 1 ? `s` : ``}
        ${invalidCardTypes.slice(0, -1).join(",").replaceAll(",", ", ")} 
        ${invalidCardTypes.length > 1 ? `and` : ``} 
        ${invalidCardTypes.slice(-1)}
        ${invalidCardTypes.length > 1 ? `are` : `is`} invalid.
        Please make sure all card types are either "Physical" or "Digital" and re-upload your file.`
      );
      return setLoading(false);
    }

    // check for any rows of the file that contain an invalid category
    if (invalidCategories.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `The Category data in row${invalidCategories.length > 1 ? `s` : ``}
        ${invalidCategories.slice(0, -1).join(",").replaceAll(",", ", ")} 
        ${invalidCategories.length > 1 ? `and` : ``} 
        ${invalidCategories.slice(-1)}
        ${invalidCategories.length > 1 ? `are` : `is`} invalid.
        Please make sure all categories are either "Purchase" or "Return" and re-upload your file.`
      );
      return setLoading(false);
    }

    // check for any card numbers in the file that are duplicates of each other
    if (duplicateNumbers.length > 0) {
      setInvalidFile(true);
      data.length = 0;
      setAlertColor("danger");
      setAlert(
        `The card number${duplicateNumbers.length > 1 ? `s` : ``} 
        ${duplicateNumbers.slice(0, -1).join(",").replaceAll(",", ", ")} 
        ${duplicateNumbers.length > 1 ? `and` : ``} 
        ${duplicateNumbers.slice(-1)}
        ${
          duplicateNumbers.length > 1 ? `are` : `is`
        } listed in your file more than once.
        Please correct and re-upload your file.`
      );
      return setLoading(false);
    }

    // set the data const to match the list const as long as the program passes all input validation checks above
    setData(list);
    setLoading(false);
    setAlertColor("success");
    setAlert(
      "If everything looks correct, your gift cards are ready to be imported"
    );
  };

  // checks for duplicates on the backend and if no duplicates are found, calls the upload function to upload the cards
  const handleSubmit = async () => {
    setLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/upload/check-duplicates/${currentUser.uid}`,
      data
    );
    const duplicates = response.data.duplicates;
    duplicates.forEach((duplicate) => matches.push(duplicate));

    // check if the uploaded file is determined to be invalid at any point in the input validation checks
    if (invalidFile) {
      setAlertColor("danger");
      setAlert("Invalid file format");
      return setLoading(false);
    }

    // check if ONE card number in uploaded file is already in the database
    if (duplicates.length === 1) {
      setAlertColor("danger");
      setAlert(
        `It looks like you already have a card with the number ${duplicates}. Please correct and re-upload your file.`
      );
      return setLoading(false);
    }

    // check if MULTIPLE card numbers in uploaded file is already in the database
    if (duplicates.length > 1) {
      setAlertColor("danger");
      setAlert(
        "The red card numbers below are already in the database. Please correct and re-upload your file."
      );
      setCardsAlreadyInDatabase(`${duplicates}`);
      return setLoading(false);
    }

    // run upload function if file is valid and there are no duplicate card numbers between file and database
    upload();
  };

  // run backend code to upload the cards into the database as long as there are no errors in the file
  const upload = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/upload/import-cards/${currentUser.uid}`,
      data
    );

    // if an 'OK' http status code of 200 is sent from the backend, display success alert and reset the file picker
    if (response.status === 200) {
      data.length = 0;
      setFileName("");
      setAlertColor("success");
      setAlert("Cards successfully imported");
    }

    setLoading(false);
  };

  return (
    <AppContainer>
      {loading && <Loading />}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            maxWidth: "1100px",
            width: "80%",
            margin: "0px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 className="import-cards-heading">Import Cards</h2>
          <div
            className="import-cards-info"
            style={{
              textAlign: "center",
              margin: "20px 0",
              display: data.length > 0 ? "none" : "block ",
            }}
          >
            Any gift cards sold previous to using Cocoa Gift Cards can be
            imported into our system. To import cards, download the template or
            upload your own CSV file. Our importer is sensitive, check the
            guidelines before uploading your file.
          </div>
          {alert && (
            <Alert variant={alertColor} className="import-alert">
              {alert}
            </Alert>
          )}
          <div
            className="import-cards-box"
            style={{
              display: data.length > 0 ? "none" : "block",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="files/gift-cards-import-template.csv"
              download
            >
              <div style={{ color: "#333333" }}>
                <Icons.Download />
                <span
                  style={{
                    color: "#6894BA",
                    marginLeft: "20px",
                  }}
                >
                  Download Template
                </span>
              </div>
            </a>
            <div
              style={{ color: "#333333", cursor: "pointer", marginTop: "10px" }}
              onClick={() => setShowInstructions(true)}
            >
              <Icons.Info />
              <span
                style={{
                  color: "#6894BA",
                  marginLeft: "20px",
                }}
              >
                Guidelines
              </span>
            </div>
            <div>
              <div
                className="import-cards-file-info"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <div style={{ display: "flex", width: "70%" }}>
                  <div>File:</div>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      marginLeft: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    {fileName ? fileName : "No file selected"}
                  </div>
                </div>
                <FilePicker
                  extensions={["csv", "txt", "xlsx", "xls"]}
                  onChange={(file) => {
                    setUserSelectedFile(file);
                    setFileName(file.name);
                    console.log(file);
                    setAlert("");
                  }}
                  onError={(err) => setAlert(err)}
                >
                  <button className="import-cards-select-file">
                    SELECT FILE
                  </button>
                </FilePicker>
              </div>
              <Button
                onClick={() => {
                  if (userSelectedFile.name) {
                    handleFile(userSelectedFile);
                  }
                }}
                style={{ width: "100%", marginTop: "60px" }}
                className="shadow-none get-balance-button"
              >
                NEXT
              </Button>
            </div>
          </div>
          {data.length !== 0 && (
            <div
              className="import-cards-found"
              style={{ margin: "20px 0", fontSize: "1.5rem" }}
            >
              {`${data.length} cards found`}
            </div>
          )}
          {fileName && !invalidFile && data.length > 0 && width < 900 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
                marginTop: "10px",
                marginBottom: "30px",
              }}
            >
              <button
                style={{ width: "142px", height: "32px" }}
                className="import-cancel-btn"
                onClick={() => {
                  setAlert("");
                  setData([]);
                  setUserSelectedFile({});
                  setInvalidFile(false);
                  setFileName("");
                }}
              >
                CANCEL
              </button>
              <Button
                style={{ width: "142px", height: "32px", fontSize: "16px" }}
                className="shadow-none reports-button"
                onClick={() => handleSubmit()}
              >
                IMPORT
              </Button>
            </div>
          ) : null}
          {displayCards()}
          {fileName && !invalidFile && data.length > 0 && width >= 900 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "600px",
                marginTop: "40px",
                marginBottom: "20px",
              }}
            >
              <button
                className="import-cancel-btn"
                onClick={() => {
                  setAlert("");
                  setData([]);
                  setUserSelectedFile({});
                  setInvalidFile(false);
                  setFileName("");
                }}
              >
                CANCEL
              </button>
              <Button
                style={{ width: "250px", height: "44px", fontSize: "16px" }}
                className="shadow-none reports-button"
                onClick={() => handleSubmit()}
              >
                IMPORT
              </Button>
            </div>
          ) : null}
          {showInstructions ? <InstructionsModal /> : null}
        </div>
      </div>
    </AppContainer>
  );
};

export default ImportCards;

const WarningIcon = () => {
  return (
    <div style={{ paddingRight: "10px" }}>
      <svg
        width="22"
        height="37"
        viewBox="0 0 22 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.084 21.25H11.9173V23.0833H10.084V21.25ZM10.084 13.9167H11.9173V19.4167H10.084V13.9167ZM10.9915 9.33334C5.93148 9.33334 1.83398 13.44 1.83398 18.5C1.83398 23.56 5.93148 27.6667 10.9915 27.6667C16.0607 27.6667 20.1673 23.56 20.1673 18.5C20.1673 13.44 16.0607 9.33334 10.9915 9.33334ZM11.0007 25.8333C6.94898 25.8333 3.66732 22.5517 3.66732 18.5C3.66732 14.4483 6.94898 11.1667 11.0007 11.1667C15.0523 11.1667 18.334 14.4483 18.334 18.5C18.334 22.5517 15.0523 25.8333 11.0007 25.8333Z"
          fill="#D32F2F"
        />
      </svg>
    </div>
  );
};

const CheckIcon = () => {
  return (
    <div style={{ paddingRight: "10px" }}>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2082 7.03184L9.16732 13.0727L5.87648 9.79101L4.58398 11.0835L9.16732 15.6668L16.5007 8.33351L15.2082 7.03184ZM11.0007 1.91684C5.94065 1.91684 1.83398 6.02351 1.83398 11.0835C1.83398 16.1435 5.94065 20.2502 11.0007 20.2502C16.0607 20.2502 20.1673 16.1435 20.1673 11.0835C20.1673 6.02351 16.0607 1.91684 11.0007 1.91684ZM11.0007 18.4168C6.94898 18.4168 3.66732 15.1352 3.66732 11.0835C3.66732 7.03184 6.94898 3.75017 11.0007 3.75017C15.0523 3.75017 18.334 7.03184 18.334 11.0835C18.334 15.1352 15.0523 18.4168 11.0007 18.4168Z"
          fill="#2E7D32"
        />
      </svg>
    </div>
  );
};

const CloseBtn = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_944_18997)">
        <path
          d="M12.4987 2.08333C6.73828 2.08333 2.08203 6.73958 2.08203 12.5C2.08203 18.2604 6.73828 22.9167 12.4987 22.9167C18.2591 22.9167 22.9154 18.2604 22.9154 12.5C22.9154 6.73958 18.2591 2.08333 12.4987 2.08333ZM17.707 16.2396L16.2383 17.7083L12.4987 13.9687L8.75911 17.7083L7.29037 16.2396L11.0299 12.5L7.29037 8.76041L8.75911 7.29166L12.4987 11.0312L16.2383 7.29166L17.707 8.76041L13.9674 12.5L17.707 16.2396Z"
          fill="#333333"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_944_18997">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Alert = ({ variant, children }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: variant === "success" ? "#edf5e0" : "",
        color: variant === "success" ? "#2E7D32" : "",
      }}
      className="alert-container"
    >
      {variant === "success" ? <CheckIcon /> : <WarningIcon />}
      {children}
    </div>
  );
};
