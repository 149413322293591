const MinusIcon = () => {
  return (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.539062 0.404297H11.2082V1.39154H0.539062V0.404297Z"
        fill="#333333"
      />
    </svg>
  );
};

export default MinusIcon;
